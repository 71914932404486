import { GlobalStateController } from "bai-react-global-state";
import { ds } from "js/core/models/dataService";
import { AssetType } from "legacy-common/constants";
import AppController from "legacy-js/core/AppController";
import { builtInFonts } from "legacy-common/fontConstants";
import { fontManager } from "js/core/services/fonts";
import { _ } from "legacy-js/vendor";

const initialState = {
    activeThemeEditorPanel: null,
    themeOrPresentationId: null,
};

class ThemeEditorController extends GlobalStateController {
    constructor(initialState) {
        super(initialState);
    }

    get activeThemeEditorPanel() {
        return this._state.activeThemeEditorPanel;
    }

    get themeOrPresentationId() {
        return this._state.themeOrPresentationId;
    }

    loadTheme(theme) {

    }

    setThemeOrPresentationId(value) {
        return this._updateState({
            themeOrPresentationId: value,
        });
    }

    setActiveThemeEditorPanel(value) {
        return this._updateState({
            activeThemeEditorPanel: value,
        });
    }

    async loadFonts(theme) {
        const fontAssets = await ds.assets.getAssetsByType(AssetType.FONT, AppController.orgId);
        const userFontIds = fontAssets.map(({ id }) => id);
        const builtInFontIds = Object.keys(builtInFonts);

        let themeFontIds = [];
        if (theme) {
            themeFontIds = Object.entries(theme)
                .filter(([key, value]) => !!value && key.endsWith("FontId"))
                .map(([key, value]) => value);
        }

        // Combine all font ids
        const fontIds = [...new Set([...userFontIds, ...builtInFontIds, ...themeFontIds])];//, ...themeFontIds])];
        // Load all fonts
        const fonts = await Promise.all(fontIds.map(fontId => fontManager.loadFont(fontId)));

        this._updateState({ fonts });
    }
}

const themeEditorController = new ThemeEditorController(_.cloneDeep(initialState));
export default themeEditorController;
