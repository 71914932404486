import React, { Component } from "react";
import { FormControl, MenuItem, OutlinedInput, Select } from "@material-ui/core";

import FontControl from "legacy-js/editor/ThemeEditor/components/Font/FontControl";
import ThemeSection from "legacy-js/editor/ThemeEditor/components/ThemeSection";
import { ThemeSpinner } from "legacy-js/editor/ThemeEditor/components/Common/ThemeCommon";
import { fontManager } from "js/core/services/fonts";
import { Gap10, Gap20, Gap30 } from "legacy-js/react/components/Gap";
import CheckBox from "legacy-js/react/components/CheckBox";
import { BetaBadge } from "legacy-js/react/components/BetaBadge";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { trackActivity } from "js/core/utilities/utilities";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";
import { ThemeSettingsPane } from "legacy-js/editor/ThemeEditor/components/ThemeSettingsPane";
import InfoToolTip from "legacy-js/react/components/InfoToolTip";

export const getCurrentFontStyle = (theme, fonts, textStyle) => {
    const fontId = (
        theme[`originalFont${textStyle}FontId`] ||
        theme[`font${textStyle}FontId`]
    );
    const fontWeight = theme[`font${textStyle}Weight`];

    const font = fonts.find(font => font.id === fontId);
    // Fonts haven't loaded yet
    if (!font) {
        return null;
    }

    let style = fontManager.getFontStyle(font.styles, fontWeight, false);
    if (theme[`originalFont${textStyle}FontId`]) {
        style.isInvalid = true;
    }

    return {
        ...style,
        fontId
    };
};

export default class ThemeTypography extends Component {
    constructor(props) {
        super(props);
        this.updateValue = this.updateValue.bind(this);

        this.state = {
            isRTL: props.theme.isRTL
        };
    }

    updateValue = attribute => event => {
        this.props.update({ [attribute]: event.target.value });
    };

    handleChangeRTL = checked => {
        this.setState({
            isRTL: checked
        });
        trackActivity("Theme", "ChangeRTL", null, null, {
            isRTL: checked
        }, { audit: false });
        this.props.update({ isRTL: checked });
    }

    handleChangeFont = (style, fontId, weight) => {
        const { update } = this.props;
        update({
            [`originalFont${style}FontId`]: null,
            [`font${style}FontId`]: fontId,
            [`font${style}Weight`]: weight,
            [`font${style}BoldWeight`]: null,
            [`font${style}LineHeight`]: 1.6,
            [`font${style}LetterSpacing`]: 0,
            [`font${style}TextTransform`]: "auto",
            [`font${style}Scaling`]: 100
        });
    }

    render() {
        const {
            theme,
            update,
            fonts,
            orgId,
            isSharedTheme,
        } = this.props;

        return (
            <ThemeSettingsPane>
                <div>
                    <FontControl
                        label="Header"
                        style="Header"
                        selectedFontId={theme.fontHeaderFontId}
                        selectedFontWeight={theme.fontHeaderWeight}
                        onSelectFont={(fontId, weight) => this.handleChangeFont("Header", fontId, weight)}
                        update={update}
                        theme={theme}
                        isSharedTheme={isSharedTheme}
                    />
                    <FontControl
                        label="Title"
                        style="Title"
                        selectedFontId={theme.fontTitleFontId}
                        selectedFontWeight={theme.fontTitleWeight}
                        onSelectFont={(fontId, weight) => this.handleChangeFont("Title", fontId, weight)}
                        update={update}
                        theme={theme}
                        isSharedTheme={isSharedTheme}
                    />
                    <FontControl
                        label="Body"
                        style="Body"
                        selectedFontId={theme.fontBodyFontId}
                        selectedFontWeight={theme.fontBodyWeight}
                        onSelectFont={(fontId, weight) => this.handleChangeFont("Body", fontId, weight)}
                        update={update}
                        theme={theme}
                        orgId={orgId}
                        isSharedTheme={isSharedTheme}
                    />
                </div>
                <div>
                    <SimpleLabel tooltip={
                        <p>
                            <p>Controls the overall scale for ALL text on Smart Slides.</p>
                            <p>NOTE: It is not recommended that you change this setting unless you really need to
                                increase text visibility when presenting. Beautiful.ai slides are tuned for the default
                                font scale and increasing the scale can result in poorer layout results.</p>
                        </p>
                    }>
                        Font Scale
                    </SimpleLabel>
                    <FormControl style={{ width: 90 }}>
                        <Select
                            value={theme.fontScale}
                            onChange={this.updateValue("fontScale")}
                            input={
                                <OutlinedInput
                                    name="fontScale"
                                    labelWidth={0}
                                />
                            }
                        >
                            <MenuItem value={1}>100%</MenuItem>
                            <MenuItem value={1.25}>125%</MenuItem>
                            <MenuItem value={1.5}>150%</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <p>
                            <p>Sets which font should be used for rendering Chinese/Japanese/Korean characters.</p>
                        </p>
                    }>
                        CJK Font
                    </SimpleLabel>
                    <FormControl style={{ width: 160 }}>
                        <Select
                            value={theme.cjkFont}
                            onChange={this.updateValue("cjkFont")}
                            input={
                                <OutlinedInput
                                    name="cjkFont"
                                    labelWidth={0}
                                />
                            }
                        >
                            <MenuItem value="jp">Japanese</MenuItem>
                            <MenuItem value="kr">Korean</MenuItem>
                            <MenuItem value="sc">Simplified Chinese</MenuItem>
                            <MenuItem value="tc">Traditional Chinese</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <FlexBox left>
                    <CheckBox
                        checked={this.state.isRTL ?? false}
                        onChange={this.handleChangeRTL}
                        label="Use RTL Text"
                    />
                    <BetaBadge />
                    <Gap10/>
                    <InfoToolTip title={
                        <p>
                            <p>Determines whether to use RTL (right-to-left) layout for most text boxes.</p>
                            <p><b>NOTE: This feature is currently in beta.</b> If you find issues or cases where the RTL feature is not implemented as you'd expect for your specific language or use case, please let us know at support@beautiful.ai.</p>
                        </p>
                    }/>
                </FlexBox>
            </ThemeSettingsPane>
        );
    }
}
