import React, { Component } from "react";
import styled from "styled-components";
import { HorizontalAlignType } from "legacy-common/constants";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";

export const ThemeSettingsPane = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-top: 10px;
  width: 100%;
  gap: 20px;
  margin-bottom: 40px;
`;

export const OptionLabel = styled.div`
  text-align: center;
  font-size: 11px;
`;

const LabeledOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-start;
`;

export function LabeledOption({ label, children, align = HorizontalAlignType.CENTER, labelMaxWidth = 50 }) {
    let alignItems = "center";
    switch (align) {
        case HorizontalAlignType.LEFT:
            alignItems = "flex-start";
            break;
        case HorizontalAlignType.RIGHT:
            alignItems = "flex-end";
            break;
        default:
            alignItems = "center";
    }

    return (
        <LabeledOptionContainer style={{ alignItems }}>
            <FlexBox>
                {children}
            </FlexBox>
            {label && <OptionLabel style={{ maxWidth: labelMaxWidth }}>{label}</OptionLabel>}
        </LabeledOptionContainer>
    );
}

