import React, { Component } from "react";
import styled from "styled-components";
import {
    Button,
    DialogActions,
    Icon
} from "@material-ui/core";

import { BeautifulDialog, DialogContent, ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import DialogTitleWithClose from "legacy-js/react/components/DialogTitleWithClose";
import { WithLabel } from "legacy-js/Components/WithLabel";
import { Dropdown } from "legacy-js/Components/Dropdown";
import { MenuItem } from "legacy-js/Components/Menu";
import { Gap20, Gap30 } from "legacy-js/react/components/Gap";
import { NumericStepper } from "legacy-js/Components/NumericStepper";
import { ColorPicker } from "legacy-js/Components/ColorPicker";
import { Slider } from "legacy-js/Components/Slider";
import { themeColors } from "legacy-js/react/sharedStyles";
import { Input } from "legacy-js/Components/Input";
import { Gradient } from "./components/Gradient";
import { BlueButton, WarningButton } from "legacy-js/react/components/UiComponents";
import { Divider } from "legacy-js/Components/Divider";
import { tinycolor } from "legacy-js/vendor";

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 30px;
`;

const PropertyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 300px;
    height: 100%;

    .with-label {
        width: 100%;
    }

    .bai-slider {
        width: 200px;
    }
`;

const GradientPreview = styled.div`
    position: relative;
    width: 100%;
    height: 495px;
    border: 1px solid #ccc;
    flex-shrink: 0;
`;

const NoiseOverlay = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
`;

const FourCornerGradientContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
`;

export class GradientBuilder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: "gradient",
            type: "linear",
            angle: 0,
            stops: [
                { color: "#FF0000", position: 0 },
                { color: "#8B00FF", position: 100 },
            ],
            noise: 0,
            ...props.gradient
        };
    }

    buildGradientCSS() {
        const { type, angle, stops } = this.state;

        if (type == "four-corner") {
            return "linear-gradient(to top left, white, rgba(255, 153, 150, 0), red), linear-gradient(to top right, yellow, rgba(255, 153, 150, 0), magenta) rgba(255, 153, 150, 1)";
        } else if (type == "radial") {
            return `radial-gradient(${stops[0].color}, ${stops[1].color})`;
        } else {
            let css = `${type}-gradient(${angle}deg,`;
            stops.forEach(stop => {
                css += `${stop.color} ${stop.position}%, `;
            });
            css = css.slice(0, -2);
            css += ")";
            return css;
        }
    }

    handleChangeType = type => {
        this.setState({ type });

        switch (type) {
            case "linear":
                this.setState({ angle: 180 });
                break;
            case "radial":
                break;
            case "four-corner":
                this.setState({
                    stops: [
                        { color: "#FF0000", position: 0 },
                        { color: "#8B00FF", position: 25 },
                        { color: "#FFAA00", position: 75 },
                        { color: "#0b9de8", position: 100 },
                    ]
                });
                break;
        }
    }

    handleSetStopColor = (index, color) => {
        const stops = [...this.state.stops];
        stops[index].color = color;
        this.setState({ stops });
    }

    handleSaveChange = () => {
        const { type, angle, stops, noise } = this.state;

        // create gradient object
        let gradient = {
            type,
            angle,
            stops,
            noise,
        };

        this.props.closeDialog(gradient);
    }

    handleCancel = () => {
        this.props.closeDialog();
    }

    render() {
        const { type, angle, stops, noise } = this.state;

        return (
            <BeautifulDialog closeDialog={this.handleCancel} maxWidth="lg">
                <DialogTitleWithClose onClose={this.handleCancel}>Custom Gradient Background</DialogTitleWithClose>
                <DialogContent>
                    <Gap20 />
                    <Container>
                        <GradientPreview>
                            <Gradient gradient={this.state} />
                        </GradientPreview>
                        <PropertyContainer>
                            <WithLabel label="Type">
                                <Dropdown value={type} onChange={this.handleChangeType}>
                                    <MenuItem value="linear">Linear</MenuItem>
                                    <MenuItem value="radial">Radial</MenuItem>
                                    <MenuItem value="four-corner">Four Corner</MenuItem>
                                </Dropdown>
                            </WithLabel>
                            {type == "linear" && (
                                <>
                                    <WithLabel label="Angle">
                                        <Slider value={angle}
                                            showInput
                                            onChange={angle => this.setState({ angle })}
                                            min={0} max={360} step={1}
                                        />
                                    </WithLabel>
                                    <Divider />
                                    <WithLabel above left label="Stops">
                                        <GradientSlider stops={stops} onChange={stops => this.setState({ stops })} />
                                    </WithLabel>
                                </>
                            )}

                            {type == "radial" && (
                                <>
                                    <Divider />
                                    <WithLabel above left label="Stops">
                                        <GradientSlider stops={stops} onChange={stops => this.setState({ stops })} />
                                    </WithLabel>
                                </>
                            )}

                            {type == "four-corner" && (
                                <>
                                    <Divider />
                                    <WithLabel label="Colors" left>
                                        <FourCornerGradientContainer>

                                            <ColorPicker value={stops[0]?.color ?? "black"}
                                                onChange={color => this.handleSetStopColor(0, color)}
                                                showColorPicker disablePalette disableAlpha
                                            />
                                            <ColorPicker value={stops[1]?.color ?? "black"}
                                                onChange={color => this.handleSetStopColor(1, color)}
                                                showColorPicker disablePalette disableAlpha
                                            />
                                            <ColorPicker value={stops[2]?.color ?? "black"}
                                                onChange={color => this.handleSetStopColor(2, color)}
                                                showColorPicker disablePalette disableAlpha
                                            />
                                            <ColorPicker value={stops[3]?.color ?? "black"}
                                                onChange={color => this.handleSetStopColor(3, color)}
                                                showColorPicker disablePalette disableAlpha
                                            />
                                        </FourCornerGradientContainer>
                                    </WithLabel>
                                </>
                            )}

                            <Divider />

                            <WithLabel label="Noise">
                                <Slider value={noise}
                                    onChange={noise => this.setState({ noise })}
                                    showInput percent
                                    min={0} max={1} step={0.01} />
                            </WithLabel>
                        </PropertyContainer>

                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel}>
                        Cancel
                    </Button>
                    <BlueButton onClick={this.handleSaveChange}>
                        Save
                    </BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

const GradientSliderContainer = styled.div`
    width: 100%;
    min-width: 300px;
    position: relative;
`;

const GradientSliderBar = styled.div`
    width: 100%;
    height: 12px;
    background: linear-gradient(90deg, ${props => props.stops.map(stop => `${stop.color} ${stop.position}%`).join(", ")});
    cursor: crosshair;
`;

const GradientStop = styled.div`
    position: absolute;
    transform: translateX(-50%);
    top: -4px;
`;

const DeleteStopButton = styled.div`
    background: ${themeColors.ui_blue};
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    opacity: 0;
    cursor: pointer;

    .MuiIcon-root {
        font-size: 12px;
    }
`;

const GradientStopEditorContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
const GradientStopEditor = styled.div`
    //border: solid 1px #ccc;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0px;
    gap: 10px;

    &:hover {
        ${DeleteStopButton} {
            opacity: 1;
        }
    }
`;

class GradientSlider extends Component {
    constructor(props) {
        super(props);

        this.ref = React.createRef();

        this.state = {
            isDragging: false,
            dragIndex: null,
            dragStartX: 0,
            dragStartPosition: 0,
            mouseDownTime: null,
            showColorPickerIndex: null
        };
    }

    handleMouseDown = (event, index) => {
        const bounds = this.ref.current.getBoundingClientRect();
        const position = (event.clientX - bounds.left) / bounds.width;

        this.setState({
            isDragging: false,
            dragIndex: index,
            dragStartX: event.clientX,
            dragStartPosition: position,
            mouseDownTime: Date.now()
        });

        window.addEventListener("mousemove", this.handleMouseMove);
        window.addEventListener("mouseup", this.handleMouseUp);
    }

    handleMouseMove = event => {
        if (this.state.dragIndex === null) return;

        const { dragIndex, dragStartX, dragStartPosition } = this.state;
        const bounds = this.ref.current.getBoundingClientRect();
        const deltaX = event.clientX - dragStartX;
        const newPosition = Math.clamp(dragStartPosition + deltaX / bounds.width, 0, 1);

        const newStops = [...this.props.stops];
        const minPosition = dragIndex > 0 ? newStops[dragIndex - 1].position / 100 + 0.01 : 0;
        const maxPosition = dragIndex < newStops.length - 1 ? newStops[dragIndex + 1].position / 100 - 0.01 : 1;

        newStops[dragIndex].position = Math.round(Math.clamp(newPosition, minPosition, maxPosition) * 100);
        newStops.sort((a, b) => a.position - b.position);

        this.props.onChange(newStops);
        this.setState({ isDragging: true });
    }

    handleMouseUp = () => {
        const { isDragging, dragIndex, mouseDownTime } = this.state;

        const mouseUpTime = Date.now();
        const clickDuration = mouseUpTime - mouseDownTime;

        if (!isDragging && dragIndex !== null && clickDuration < 500) {
            // Show color picker only if it was a click, not a drag
            this.setState({ showColorPickerIndex: dragIndex });
        } else {
            this.setState({ showColorPickerIndex: null });
        }

        window.removeEventListener("mousemove", this.handleMouseMove);
        window.removeEventListener("mouseup", this.handleMouseUp);

        this.setState({ isDragging: false, dragIndex: null });
    }

    handleAddStop = event => {
        if (this.props.stops.length >= 10) {
            ShowErrorDialog({ title: "Sorry, you can only have up to 10 stops in a gradient." });
            return;
        }

        const bounds = this.ref.current.getBoundingClientRect();
        const position = Math.clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

        const newStops = [...this.props.stops];

        //interpolate color from previous and next stops
        let prevStop = newStops.find(stop => stop.position < position);
        let nextStop = newStops.find(stop => stop.position > position);

        let color = "#000000";
        if (prevStop && nextStop) {
            let prevColor = tinycolor(prevStop.color).toRgb();
            let nextColor = tinycolor(nextStop.color).toRgb();
            let prevPosition = prevStop.position / 100;
            let nextPosition = nextStop.position / 100;
            let t = (position - prevPosition) / (nextPosition - prevPosition);
            color = tinycolor({
                r: Math.round(prevColor.r + (nextColor.r - prevColor.r) * t),
                g: Math.round(prevColor.g + (nextColor.g - prevColor.g) * t),
                b: Math.round(prevColor.b + (nextColor.b - prevColor.b) * t),
            }).toHexString();
        }

        newStops.push({ color: color, position: Math.round(position * 100) });
        newStops.sort((a, b) => a.position - b.position);

        this.props.onChange(newStops);
    }

    render() {
        const { stops, onChange } = this.props;
        const { isDragging, showColorPickerIndex } = this.state;

        return (
            <GradientSliderContainer ref={this.ref}>
                <GradientSliderBar stops={stops} onClick={this.handleAddStop} />
                {stops.map((stop, index) => (
                    <GradientStop
                        key={index}
                        style={{ left: `${stop.position}%` }}
                        onMouseDown={event => this.handleMouseDown(event, index)}
                    >
                        <ColorPicker value={stop.color}
                            onChange={color => {
                                const newStops = [...stops];
                                newStops[index].color = tinycolor(color).toHexString();
                                onChange(newStops);
                            }}
                            showColorPicker disablePalette disableAlpha
                            disabled={isDragging || showColorPickerIndex !== index}
                            bottomControls={
                                closePopup => {
                                    return stops.length > 2 ? (
                                        <div>
                                            <Divider />
                                            <Gap20/>
                                            <WarningButton
                                                style={{ width: "100%" }}
                                                size="small"
                                                onClick={() => {
                                                    const newStops = [...stops];
                                                    newStops.splice(index, 1);
                                                    onChange(newStops);
                                                    closePopup();
                                                }}
                                            >
                                                Delete Stop
                                            </WarningButton>
                                        </div>
                                    ) : null;
                                } }
                        />
                    </GradientStop>
                ))}
                <Gap30 />

                <GradientStopEditorContainer>
                    {stops.map((stop, index) => (
                        <GradientStopEditor key={index}>
                            <ColorPicker value={stop.color}
                                onChange={color => {
                                    const newStops = [...stops];
                                    newStops[index].color = tinycolor(color).toHexString();
                                    onChange(newStops);
                                }}
                                showColorPicker disablePalette disableAlpha
                                disabled={isDragging || showColorPickerIndex !== index}
                            />
                            <Input value={stop.color}
                                width={120}
                                onChange={color => {
                                    const newStops = [...stops];
                                    newStops[index].color = color;
                                    onChange(newStops);
                                }}
                            />
                            <NumericStepper value={stop.position / 100}
                                onChange={position => {
                                    const newStops = [...stops];
                                    newStops[index].position = position * 100;
                                    newStops.sort((a, b) => a.position - b.position);
                                    onChange(newStops);
                                }}
                                min={0} max={100} step={1} format="percent"
                            />
                            {stops.length > 2 && <DeleteStopButton onClick={() => {
                                const newStops = [...stops];
                                newStops.splice(index, 1);
                                onChange(newStops);
                            }}>
                                <Icon>close</Icon>
                            </DeleteStopButton>}
                        </GradientStopEditor>
                    ))}
                </GradientStopEditorContainer>
            </GradientSliderContainer>
        );
    }
}
