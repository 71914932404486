import theme from "legacy-js/react/materialThemeOverrides";
import { withStyles } from "@material-ui/core/styles";
import UploadImage from "legacy-js/react/components/UploadImage";

const { primary, grey } = theme.palette;

const uploadLogoStyle = {
    root: {
    },
    logoParent: {
        width: 160,
        height: 90,
        border: `1px solid ${grey[100]}`,
        position: "relative",
        "& .uploadIcon, & img": {
            display: "none",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto"
        },
        "& img": {
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block"
        },
        "&:hover .uploadIcon": {
            display: "block"
        }
    },
    label: {
        fontSize: "11px !important",
        textAlign: "center",
        display: "block",
        marginTop: 6
    },
    paper: {
        backgroundColor: "#fff",
        borderRadius: 0
    },
    circleIconParent: {
        backgroundColor: primary[500],
        padding: "7%",
        borderRadius: "50%",
        cursor: "pointer",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#0e90c1"
        }
    },
    spinner: {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto"
    }
};

export default withStyles(uploadLogoStyle)(UploadImage);
