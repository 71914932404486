import React, { Component } from "react";
import FontList from "./FontList";
import FontSettings from "./FontSettings";
import styled from "styled-components";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";

const FontLabel = styled.h4`
    color: #333;
`;

const FontContainer = styled.div`
    margin-left: 0;
    width: 100%;
    position: relative;
`;

export default class FontControl extends Component {
    render() {
        const {
            label,
            simple = false,
        } = this.props;

        return (
            <FontContainer>
                <SimpleLabel>{label}</SimpleLabel>
                <FlexBox left middle>
                    <FontList {...this.props} />
                    <FontSettings {...this.props} />
                </FlexBox>
            </FontContainer>
        );
    }
}
