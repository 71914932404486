import React, { Component } from "react";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { SlideGrid } from "legacy-js/editor/slideGrid";
import { $, _ } from "legacy-js/vendor";

class SlideGridWrapper extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }

    componentDidMount() {
        const { presentation, slides, currentSlide, onClose } = this.props;

        this.slideGrid = new SlideGrid({ presentation, slides, currentSlide, callback: onClose });
        $(this.ref.current).append(this.slideGrid.render().$el);
    }

    componentWillUnmount() {
        if (this.slideGrid) {
            this.slideGrid.remove();
        }
    }

    render() {
        return (
            <div ref={this.ref} />
        );
    }
}

export default PresentationEditorController.withState(SlideGridWrapper);
