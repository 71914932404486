import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "@material-ui/core";
import { ds } from "js/core/models/dataService";

const StyledLink = styled(Link)`
    font-size: 12px;
    /** overridubg naterial ui .MuiLink-button class prop */
    vertical-align: baseline !important;
`;

export class VideoOverlayPanel extends Component {
    handleSelect = () => {
        const { canvas, onClose } = this.props;
        const element = canvas.layouter.elements.annotations.videoOverlay;
        ds.selection.element = element;
        onClose();
    }

    render() {
        return (
            <StyledLink
                component="button"
                onClick={this.handleSelect}>
                Select Bubble
            </StyledLink>
        );
    }
}

