import React, { useEffect, useState } from "react";
import styled from "styled-components";

import getLogger, { LogGroup } from "js/core/logger";
import { tinycolor, _ } from "legacy-js/vendor";
import PresentationEditorController, { PanelType } from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { getLogoPath } from "legacy-common/assetUtils";
import Logos from "js/core/models/logos";
import { trackActivity } from "js/core/utilities/utilities";
import { BackgroundStyleType, ForeColorType, PaletteColorType } from "legacy-common/constants";
import { Divider } from "legacy-js/react/components/UiComponents";
import { FeatureType } from "legacy-common/features";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";

import { ShowDialog, ShowMessageDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { ChooseThemeDialog } from "legacy-js/editor/ThemeEditor/ChooseThemeDialog";
import AppController from "legacy-js/core/AppController";
import ThemeEditor from "legacy-js/editor/ThemeEditor/ThemeEditor";
import { Gradient } from "../../ThemeEditor/components/Gradient";

const logger = getLogger(LogGroup.EDITOR);

export const COLOR_PANEL_WIDTH = 125;

const Container = styled.div`
    flex-direction: column;
    align-self: flex-start;
    display: flex;
    height: 100%;
    background: #222;
    padding: 10px 12px 65px;
    align-items: center;
    width: 125px;
    overflow: auto;

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 24px;
        height: 0px;
    }

    .button.edit-theme {
        color: white;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        &.team-theme .micon {
            background: red;
            border-radius: 100%;
            padding: 2px;
            margin-bottom: 3px;
            color: white;

            &:hover {
                color: #50bbe6;
            }
        }
    }

    hr {
        border: none;
        background: #444;
        height: 1px;
        margin: 20px 0px 20px;
    }

    .label {
        font-size: 12px;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 2px;
        margin-bottom: 16px;
        text-align: center;
    }

    .button {
        cursor: pointer;
        padding: 10px 0px;
        color: #333;
        transition: 250ms;
        text-align: center;

        label {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
            color: white;
        }

        &:hover {
            color: #50bbe6;
        }
    }

    .color_group {
        margin-bottom: 20px;
    }

    .color_list {
        padding: 0px 9px 0px 15px;
        margin-right: -5px;
        min-width: 104px;

        &.disabled {
            opacity: 0.2;
        }

        .color_chit {
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
`;

const BackgroundImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const ThemeBackgroundImageThumbnail = styled.div`
    background: #fff;
    width: 100px;
    height: 100%;
    margin-bottom: 10px;

    img {
        width: 100%;
        height: 100%;
    }
`;

const ColorPanel = ({ presentation, currentCanvasController, allowThemeChange }) => {
    const { user, themeManager } = app;
    const { teams } = ds;
    const isTeamTheme = presentation.has("sharedThemeId");
    const orgId = presentation.get("orgId");
    const canEditLibrary = orgId ? user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, orgId) : false;

    const currentCanvas = currentCanvasController.canvas;
    const currentTheme = currentCanvas.getTheme();

    const [selectedColor, setSelectedColor] = useState(currentCanvas.model.layout.slideColor);
    const [colors, setColors] = useState([]);
    const [slideColorLabel, setSlideColorLabel] = useState("");
    const [backgroundColorsLabel, setBackgroundColorsLabel] = useState("");
    const [backgroundColors, setBackgroundColors] = useState([]);
    const [backgroundImages, setBackgroundImages] = useState([]);
    const [backgroundGradients, setBackgroundGradients] = useState([]);

    useEffect(() => {
        updateColors();
    }, [currentCanvas]);

    const getbackgroundImages = () => {
        const themeBackgroundImages = currentTheme.get("backgroundImages") ?? [];

        Promise.all(
            themeBackgroundImages
                .filter(backgroundImage => !!backgroundImage.asset)
                .map(async backgroundImage => ({
                    url: await Logos.getSignedUrlAndLoad(getLogoPath(backgroundImage.asset)),
                    backgroundImage
                }))
        ).then(results => setBackgroundImages(results))
            .catch(err => logger.error(err, `[COLOR PANEL] Error loading background images`));
    };

    const getBackgroundGradients = () => {
        const themeBackgroundGradients = currentTheme.get("backgroundGradients") ?? [];
        setBackgroundGradients(themeBackgroundGradients);
    };

    const getSlideColorsLabel = () => {
        if (currentCanvas.layouter.elements.primary.getCustomSlideColorsLabel) {
            setSlideColorLabel(currentCanvas.layouter.elements.primary.getCustomSlideColorsLabel());
        } else {
            setSlideColorLabel("Slide Color");
        }
    };

    const getBackgroundColorsLabel = () => {
        if (currentCanvas.layouter.elements.primary.getCustomBackgroundColorsLabel) {
            setBackgroundColorsLabel(currentCanvas.layouter.elements.primary.getCustomBackgroundColorsLabel());
        } else {
            setBackgroundColorsLabel("Background");
        }
    };

    const getSlideColors = () => {
        if (currentCanvas.layouter.elements.primary.getCustomSlideColors) {
            setColors(currentCanvas.layouter.elements.primary.getCustomSlideColors());
        } else {
            let allowColors = false;
            let currentBackgroundStyle = currentCanvas.layouter.elements.background.canvasBackgroundStyle;
            if (currentBackgroundStyle.equalsAnyOf(BackgroundStyleType.LIGHT, BackgroundStyleType.DARK, BackgroundStyleType.ACCENT)) {
                allowColors = true;
            } else if (currentBackgroundStyle == BackgroundStyleType.IMAGE) {
                allowColors = currentCanvas.layouter.elements.background.backgroundImage?.colorStyle != "color";
            }

            let slideColors = Object.keys(currentTheme.palette.getSlideColors()).map(key => ({
                name: key,
                color: currentTheme.palette.getSlideColors()[key],
                enabled: allowColors
            }));

            slideColors.push({
                name: "colorful",
                enabled: allowColors
            });
            slideColors.push({ name: "auto", enabled: true });
            setColors(slideColors);
        }
    };

    const getBackgroundColors = () => {
        if (currentCanvas.layouter.elements.primary.getCustomBackgroundColors) {
            setBackgroundColors(currentCanvas.layouter.elements.primary.getCustomBackgroundColors());
        } else {
            let backgroundColors = [];
            _.each(currentTheme.palette.getBackgroundColors(currentCanvas.slideTemplate.allowColorBackgrounds), (color, key) => {
                backgroundColors.push({
                    name: key,
                    color: color,
                    enabled: true
                });
            });
            setBackgroundColors(backgroundColors);
        }
    };

    const getEditThemePanelOptions = theme => {
        const options = {
            theme,
            onSuccess: theme => {
                themeManager.switchTheme(theme);
                PresentationEditorController.updateTheme();
            },
        };
        const workspaceId = presentation.getWorkspaceId();
        //if the presentation is using a team theme and belongs to an org...
        if (presentation.has("sharedThemeId") &&
            workspaceId !== "personal") {
            const defaultTeam = teams.defaultTeamForOrg(workspaceId);
            const presentationSharedThemeId = presentation.get("sharedThemeId");

            //If the librarian/owner is part of the same org as the team theme, open the team theme editor...
            if (user.checkIfUserAndPresentationBelongToSameOrg(workspaceId) &&
                user.features.isFeatureEnabled(FeatureType.EDIT_LIBRARY_ITEMS, workspaceId) &&
                defaultTeam.checkIfSharedThemeBelongsToOrg(presentationSharedThemeId)) {
                options.isSharedThemeEditor = true;
                options.onClosed = () => {
                    const props = {
                        workspace_id: workspaceId,
                        theme_id: presentationSharedThemeId
                    };
                    trackActivity("OrgTheme", "Edited", null, null, props);
                };
            }
        }

        return options;
    };

    const switchTheme = () => {
        PresentationEditorController.togglePanel(PanelType.COLOR);

        // check if team themes are required
        let onlyTeamThemes = false;

        const isUserInOrg = user.checkIfUserAndPresentationBelongToSameOrg(orgId);

        if (orgId) {
            if (isUserInOrg) {
                const defaultTeam = teams.defaultTeamForOrg(orgId);
                if (defaultTeam) {
                    onlyTeamThemes = !user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, orgId);
                }
            } else {
                // note that we *do not* have access to the foreign team to see if it has `isSharedThemeRequired` set
                ShowMessageDialog({
                    title: "Cannot switch theme",
                    message: "This presentation belongs to another organization. Only members of that organization can switch the theme."
                });
                return;
            }
        }

        ds.selection.element = null;
        ShowDialog(ChooseThemeDialog, {
            title: "Switch Theme",
            hideUserThemes: onlyTeamThemes,
            hideBuiltInThemes: onlyTeamThemes,
            onlyTeamThemes,
            callback: theme => {
                const options = getEditThemePanelOptions(theme);
                options.onSuccess(theme);
            }
        });
    };

    const onColorSelection = (event, onColorSelected) => {
        if (!event.currentTarget.parentElement.classList.contains("disabled")) {
            onColorSelected(event.currentTarget.dataset.colorName);
            setSelectedColor(event.currentTarget.dataset.colorName);
        }
    };

    const colorOptions = (colors, onColorSelected) => {
        const canvasBackground = currentCanvas.getBackgroundColor().toRgbString();
        return colors.map((color, index) => {
            const disabled = !color.enabled ? "disabled" : "";
            const lightColor = tinycolor(color).getLuminance() > 0.95 ? "light-color" : "";
            const selected = color.name === selectedColor ? "selected" : "";
            switch (color.name) {
                case "colorful":
                    return (
                        <div key={index} className={`colorful_chit color_chit can-disable ${disabled} ${selected}`} data-color-name="colorful"
                            onClick={e => onColorSelection(e, onColorSelected)}>
                            <div className="container">
                                {
                                    currentTheme.palette.getColorfulColors().map((color, index) => (<div key={index}
                                        style={{
                                            background: currentTheme.palette.getColor(color).toRgbString()
                                        }}></div>))
                                }
                            </div>
                            {
                                !color.enabled && (<div className="disabled-chit"></div>)
                            }
                        </div>
                    );
                case "auto":
                    return (
                        <div key={index} className={`color_chit ${selected}`} data-color-name="neutral" style={{
                            color: currentTheme.palette.getForeColor("primary", null, currentCanvas.getBackgroundColor())
                        }} onClick={e => onColorSelection(e, onColorSelected)}>
                            <div className={`color_label ${disabled}`}>AUTO</div>
                        </div>
                    );
                default:
                    return (
                        <div key={index} className={`color_chit can-disable ${selected}`}
                            data-color-name={color.name}
                            style={{ color: color.color }}
                            onClick={e => onColorSelection(e, onColorSelected)}>
                            <div className={`background  ${disabled} ${lightColor}`} style={{
                                background: canvasBackground
                            }}></div>
                            {
                                !color.enabled && (<div className="disabled-chit"></div>)
                            }
                        </div>);
            }
        });
    };

    const createColorGroup = (colors, label, className, onColorSelected) => {
        return (<div className={`color_group ${className}`}>
            <div className="label">{label}</div>
            <div className="color_list">
                {colorOptions(colors, onColorSelected)}
            </div>
        </div>);
    };

    const renderSlideColors = () => {
        return createColorGroup(colors, slideColorLabel, "foreground", color => {
            currentCanvas.model.layout.slideColor = color;
            currentCanvas.getCanvasElement().resetUserColors();
            currentCanvas.markStylesAsDirty();
            currentCanvas.updateCanvasModel(true, true);
        });
    };

    const canvasBGColor = () => {
        switch (currentCanvas.model.layout.backgroundStyle) {
            case BackgroundStyleType.DARK:
                return PaletteColorType.BACKGROUND_DARK;
            case BackgroundStyleType.LIGHT:
                return PaletteColorType.BACKGROUND_LIGHT;
            case BackgroundStyleType.COLOR:
            default:
                return currentCanvas.model.layout.backgroundColor;
        }
    };

    const backgroundCallback = color => {
        switch (color) {
            case PaletteColorType.BACKGROUND_DARK:
                if (currentCanvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && currentCanvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                    currentCanvas.model.layout.slideColor = PaletteColorType.THEME;
                }
                currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.DARK;
                break;
            case PaletteColorType.BACKGROUND_LIGHT:
                if (currentCanvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && currentCanvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                    currentCanvas.model.layout.slideColor = PaletteColorType.THEME;
                }
                currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.LIGHT;
                break;
            case PaletteColorType.BACKGROUND_ACCENT:
                if (currentCanvas.model.layout.backgroundStyle == BackgroundStyleType.COLOR && currentCanvas.model.layout.slideColor == ForeColorType.NEUTRAL) {
                    currentCanvas.model.layout.slideColor = PaletteColorType.THEME;
                }
                currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.ACCENT;
                break;
            default:
                currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.COLOR;
                currentCanvas.model.layout.slideColor = ForeColorType.NEUTRAL;
        }
        currentCanvas.model.layout.backgroundColor = color;
        currentCanvas.markStylesAsDirty();

        currentCanvas.updateCanvasModel(true, true).then(() => updateColors());
    };

    const updateColors = () => {
        getSlideColors();
        getSlideColorsLabel();
        getBackgroundColorsLabel();
        getBackgroundColors();
        getbackgroundImages();
        getBackgroundGradients();
    };

    const renderBackgroundLogoColors = () => {
        const handleBackgroundImageClick = backgroundImage => {
            currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.IMAGE;
            currentCanvas.model.layout.backgroundColor = backgroundImage.id;
            currentCanvas.markStylesAsDirty();
            currentCanvas.updateCanvasModel(true, true).then(() => updateColors());
        };

        return (<BackgroundImagesContainer>
            {backgroundImages.map(({ backgroundImage, url }, index) => (<ThemeBackgroundImageThumbnail key={index}
                onClick={() => handleBackgroundImageClick(backgroundImage)}>
                <img src={url} />
            </ThemeBackgroundImageThumbnail>))}
        </BackgroundImagesContainer>);
    };

    const renderGradients = () => {
        const handleGradientClick = backgroundGradient => {
            currentCanvas.model.layout.backgroundStyle = BackgroundStyleType.GRADIENT;
            currentCanvas.model.layout.backgroundColor = backgroundGradient.id;
            currentCanvas.markStylesAsDirty();
            currentCanvas.updateCanvasModel(true, true).then(() => updateColors());
        };

        return (<BackgroundImagesContainer>
            {backgroundGradients.map((backgroundGradient, index) => (
                <ThemeBackgroundImageThumbnail key={index}
                    onClick={() => handleGradientClick(backgroundGradient)}>
                    <div style={{ width: "100%", height: "60px" }}>
                        <Gradient gradient={backgroundGradient} />
                    </div>
                </ThemeBackgroundImageThumbnail>
            ))}
        </BackgroundImagesContainer>);
    };

    return (
        <Container>
            {renderSlideColors()}
            {createColorGroup(backgroundColors, backgroundColorsLabel, canvasBGColor(), backgroundCallback)}
            {renderGradients()}
            {renderBackgroundLogoColors()}
            {allowThemeChange && (<>
                <Divider margin={10} color="#444" />
                <div
                    className="button edit-theme"
                    onClick={() => switchTheme()}
                >
                    <i className="micon">cached</i>
                    <label>Switch Theme...</label>
                </div>
                <div
                    className={`button edit-theme 
                    ${isTeamTheme && !canEditLibrary ? "disabled" : ""}
                    ${presentation.has("sharedThemeId") ? "team-theme" : ""}`}
                    onClick={() => {
                        if (window.isPPTAddin) {
                            ShowDialog(ThemeEditor, {
                                themeOrPresentationId: presentation.id,
                            });
                        } else {
                            AppController.showThemeEditor({ themeOrPresentationId: presentation.id });
                        }
                    }}
                >
                    <i className="micon">{presentation.has("sharedThemeId") ? "link" : "palette"}</i>
                    <label>Edit Theme...</label>
                </div>
            </>)}
        </Container>
    );
};

export default PresentationEditorController.withState(ColorPanel);

