import React, { Component } from "react";
import styled from "styled-components";

// export const Divider = styled.div`
//     // Adding &&& to override the material ui style
//     &&& {
//         height: 1px;
//         background: ${props => props.color || "#eee"};
//         width: 100%;
//         margin-top: ${props => props.margin != null ? props.margin : 0}px;
//         margin-bottom: ${props => props.margin != null ? props.margin : 0}px;
//     }
// `;

export function Divider(props) {
    return (
        <div className="divider"
            style={{
                height: 1,
                background: props.color || "#eee",
                width: "100%",
                marginTop: props.margin != null ? props.margin : 0,
                marginBottom: props.margin != null ? props.margin : 0,
                paddingTop: 0,
                paddingBottom: 0,
                ...props.style
            }}
        />
    );
}
