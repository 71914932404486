import React, { Component } from "react";
import styled from "styled-components";

import { $, _ } from "legacy-js/vendor";

const CanvasFrame = styled.div.attrs(({ opacity }) => ({ style: { opacity: opacity ?? 1 } }))``;

export class CanvasWrapper extends Component {
    componentDidMount() {
        this.renderCanvas();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { onCanvasReloaded = () => { }, canvas } = this.props;

        if (prevProps.canvas !== canvas) {
            this.renderCanvas();
            onCanvasReloaded();
        }
    }

    handleTransitionEnd = event => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    async renderCanvas() {
        const { canvas, wrapperRef } = this.props;
        if (!canvas) {
            return;
        }

        $(wrapperRef.current).empty();
        $(wrapperRef.current).append(canvas.$el);
    }

    render() {
        const { canvasWidth, canvasHeight, slide, currentSlide, opacity, wrapperRef } = this.props;

        const showCanvasShadow = slide.get("template_id") !== "slidePlaceholder";

        return (
            <CanvasFrame
                ref={wrapperRef}
                opacity={opacity}
                className={currentSlide === slide ? "current_slide" : null}
                onTransitionEnd={this.handleTransitionEnd}
                style={{
                    width: canvasWidth,
                    height: canvasHeight,
                    boxShadow: showCanvasShadow ? "rgb(0 0 0 / 40%) 0px 26px 40px" : "none"
                }}
            />
        );
    }
}

