import React, { Component } from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";
import { AnimationsArrangementType } from "legacy-common/constants";

import { BoxButton } from "../BoxButton";
import { Gap10 } from "legacy-js/react/components/Gap";

const Container = styled.div`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Header = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #222;
`;

const ControlsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export function AnimationArrangementControl({ isAnimating, animationsArrangementType, setAnimationsArrangementType }) {
    return (
        <Container>
            <Header>timing</Header>
            <Gap10/>
            <ControlsContainer>
                <BoxButton
                    selected={animationsArrangementType === AnimationsArrangementType.SEQUENTIAL}
                    onClick={() => setAnimationsArrangementType(AnimationsArrangementType.SEQUENTIAL)}
                    disabled={isAnimating}
                    imageUrl={getStaticUrl("/images/ui/animation/animation-sequential.svg")}
                    labelText={"sequential"}
                    width={"calc((100% - 10px) / 3)"}
                    containerStyle={{ marginRight: "5px" }}
                />
                <BoxButton
                    selected={animationsArrangementType === AnimationsArrangementType.OVERLAPPING}
                    onClick={() => setAnimationsArrangementType(AnimationsArrangementType.OVERLAPPING)}
                    disabled={isAnimating}
                    imageUrl={getStaticUrl("/images/ui/animation/animation-overlapping.svg")}
                    labelText={"staggered"}
                    width={"calc((100% - 10px) / 3)"}
                    containerStyle={{ marginRight: "5px" }}
                />
                <BoxButton
                    selected={animationsArrangementType === AnimationsArrangementType.SIMULTANEOUS}
                    onClick={() => setAnimationsArrangementType(AnimationsArrangementType.SIMULTANEOUS)}
                    disabled={isAnimating}
                    imageUrl={getStaticUrl("/images/ui/animation/animation-simultaneous.svg")}
                    labelText={"together"}
                    width={"calc((100% - 10px) / 3)"}
                />
            </ControlsContainer>
        </Container>
    );
}
