import React, { Component } from "react";
import { $ } from "legacy-js/vendor";
import { UIController } from "../dialogs/UIController";
import { WhiteIconButton } from "legacy-js/react/components/UiComponents";
import { Icon } from "@material-ui/core";
import { Key } from "js/core/utilities/keys";
import { ds } from "js/core/models/dataService";
import { PanelType } from "../PresentationEditor/PresentationEditorController";
import AppController from "legacy-js/core/AppController";

export class HelpButton extends Component {
    componentDidMount() {
        // Global shortcuts
        $(window).on("keydown.help", event => {
            //Protect against input fields so that we don't accidentally trigger these key events.
            if (event.target.tagName === "INPUT") {
                return;
            }
            event.stopPropagation();
            switch (event.which) {
                // Open the help menu on Cmd/Ctrl + ?
                case Key.FORWARD_SLASH:
                    if (event.metaKey || event.ctrlKey) {
                        AppController.setExternalActivePanel(PanelType.SHORTCUTS);
                        event.preventDefault();
                        return;
                    }
                    break;
            }
        });
    }

    componentWillUnmount() {
        // Stop listening for shortcuts
        $(window).off("keydown.help");
    }

    handleShowHelpPanel = event => {
        ds.selection.element = null;
        UIController.showHelpPanel(this.props.source, event.shiftKey);
    }

    render() {
        return (
            <WhiteIconButton className="help-button" onClick={this.handleShowHelpPanel}>
                <Icon>help</Icon>
            </WhiteIconButton>
        );
    }
}
