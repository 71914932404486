import React, { Component } from "react";
import styled from "styled-components";

import { Icon, MenuItem } from "@material-ui/core";
import { PaletteColorType } from "legacy-common/constants";
import { getStaticUrl } from "legacy-js/config";
import { blendColors } from "js/core/utilities/utilities";
import ColorChit from "legacy-js/editor/ThemeEditor/components/Color/ColorChit";
import { ColorChitCollection } from "legacy-js/editor/ThemeEditor/components/Color/ColorChitCollection";
import DefaultBackgroundColor from "legacy-js/editor/ThemeEditor/components/Color/DefaultBackgroundColor";
import PredefinedColorPalettePicker from "legacy-js/editor/ThemeEditor/components/Color/PredefinedColorPalettePicker";
import { ThemeBackgroundImages } from "legacy-js/editor/ThemeEditor/components/ThemeBackgroundImages";
import ThemeOptions from "legacy-js/editor/ThemeEditor/components/ThemeOptions";
import { LabeledOption, ThemeSettingsPane } from "legacy-js/editor/ThemeEditor/components/ThemeSettingsPane";
import { Gap10 } from "legacy-js/react/components/Gap";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";
import { tinycolor } from "legacy-js/vendor";
import { ShowDialog } from "../../../react/components/Dialogs/BaseDialog";
import { GradientBuilder } from "../GradientBuilder";
import { ThemeGradients } from "../components/ThemeGradients";

const backgroundStyle = [
    {
        value: "none",
        label: "None",
        icon: <img src={getStaticUrl("/images/theme_editor/Shape-None.svg")} />,
    },
    {
        value: "vignette",
        label: "Vignette",
        icon: <img src={getStaticUrl("/images/theme_editor/Background-Vignette.svg")} />,
    },
    {
        value: "gradient",
        label: "Gradient",
        icon: <img src={getStaticUrl("/images/theme_editor/Background-Gradient.svg")} />,
    },
    {
        value: "reverseGradient",
        label: "Reverse",
        icon: <img src={getStaticUrl("/images/theme_editor/Background-Reverse.svg")} />,
    },
    {
        value: "border",
        label: "Border",
        icon: <img src={getStaticUrl("/images/theme_editor/Background-Border.svg")} />,
    }
];

const StyledHr = styled.hr`
  margin: 30px 30px 10px 0px;
  background: #bbb !important;
`;

const ColorPaletteContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const ColorLabel = styled.div`
  text-align: center;
  font-size: 11px;
  margin-left: -10px;
  margin-bottom: 12px;
  //margin-top: 6px;
`;

export default class ThemeColors extends Component {
    state = {
        forceRefresh: false,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme.colors !== this.props.theme.colors) {
            this.flagForcedRefresh();
        }
    }

    replacePalette = palette => {
        if (!palette.colors.hasOwnProperty("chart1")) {
            let index = 2;
            palette.colors["chart1"] = palette.colors.theme;

            for (let colorName of Object.keys(palette.colors)) {
                if (colorName.startsWith("accent")) {
                    palette.colors["chart" + index] = palette.colors[colorName];
                    index++;
                }
            }
        }
        if (!palette.colors.hasOwnProperty(PaletteColorType.BACKGROUND_ACCENT)) {
            let blendColor = tinycolor(palette.colors[PaletteColorType.THEME]).setAlpha(0.1);
            palette.colors[PaletteColorType.BACKGROUND_ACCENT] = blendColors(blendColor, tinycolor("white")).toRgbString();
        }

        if (!palette.colors.hasOwnProperty(PaletteColorType.POSITIVE)) {
            palette.colors[PaletteColorType.POSITIVE] = "#54C351";
        }
        if (!palette.colors.hasOwnProperty(PaletteColorType.NEGATIVE)) {
            palette.colors[PaletteColorType.NEGATIVE] = "#E04C2B";
        }

        this.props.update(palette);
    };

    updateColor = (name, color) => {
        let { colors } = this.props.theme;
        colors = { ...colors, [name]: color };
        this.props.update({ colors, palette_name: "custom" });
    };

    handleUpdateColors = colors => {
        let paletteColors = Object.assign({}, this.props.theme.colors);

        // delete all accent colors
        for (let name of Object.keys(paletteColors)) {
            if (name.startsWith("accent")) {
                delete paletteColors[name];
            }
        }

        // add theme and accent colors
        for (let i = 0; i < colors.length; i++) {
            if (i == 0) {
                paletteColors.theme = colors[0];
            } else {
                paletteColors["accent" + i] = colors[i];
            }
        }

        this.props.update({
            colors: paletteColors,
            palette_name: "custom"
        });
    }

    handleUpdateChartColors = colors => {
        let paletteColors = Object.assign({}, this.props.theme.colors);

        // delete all chart colors
        for (let name of Object.keys(paletteColors)) {
            if (name.startsWith("chart")) {
                delete paletteColors[name];
            }
        }

        for (let i = 0; i < colors.length; i++) {
            paletteColors["chart" + (i + 1)] = colors[i];
        }

        this.props.update({
            colors: paletteColors,
            palette_name: "custom"
        });
    };

    updateBackgroundStyle = value => {
        const defaultBackgroundColor = this.props.theme
            .defaultBackgroundColor;
        switch (value.styleBackground) {
            case "gradient":
            case "reverseGradient":
                if (
                    !defaultBackgroundColor ||
                    defaultBackgroundColor ===
                    PaletteColorType.BACKGROUND_LIGHT
                ) {
                    this.props.update({
                        ...value,
                        defaultBackgroundColor: "theme"
                    });
                } else {
                    this.props.update(value);
                }
                break;
            default:
                this.props.update({
                    ...value,
                    defaultBackgroundColor:
                        this.props.theme.lastDefaultBackgroundColor ||
                        PaletteColorType.BACKGROUND_LIGHT
                });
        }
    };

    applyBaseColorsToChartColors = () => {
        let {
            theme,
        } = this.props;

        let accentColors = Object.entries(theme.colors)
            .filter(([name]) => name.startsWith("accent"))
            .sort(([nameA], [nameB]) => nameA < nameB ? -1 : 1)
            .map(([, color]) => color);

        let chartColors = [
            theme.colors.theme,
            ...accentColors,
        ];

        this.handleUpdateChartColors(chartColors);

        this.flagForcedRefresh();
    }

    flagForcedRefresh = () => {
        if (!this.state.forceRefresh) {
            this.setState({ forceRefresh: true });

            setTimeout(() => {
                this.setState({ forceRefresh: false });
            }, 10);
        }
    }

    handleAddGradient = () => {
        let dlg = ShowDialog(GradientBuilder);
    }

    render() {
        let {
            theme,
        } = this.props;

        let {
            forceRefresh,
        } = this.state;

        let paletteColors = [];
        let chartColors = [];

        paletteColors.push(theme.colors.theme);

        // accent and chart contain text + number we want to sort it based of the number
        // and not text
        const sortedKeys = Object.keys(theme.colors).filter(key => key.startsWith("accent") || key.startsWith("chart")).sort((a, b) => {
            const numA = parseInt(a.match(/\d+/)[0]);
            const numB = parseInt(b.match(/\d+/)[0]);

            return numA - numB;
        });

        for (let colorName of sortedKeys) {
            if (colorName.startsWith("accent")) {
                paletteColors.push(theme.colors[colorName]);
            }
            if (colorName.startsWith("chart")) {
                chartColors.push(theme.colors[colorName]);
            }
        }

        return (
            <ThemeSettingsPane>
                <div>
                    <SimpleLabel tooltip={
                        <>
                            <p>The color palette defines the theme colors available for decorations, shapes, accents,
                                and emphasized text. You can choose up to 10 colors and drag to reorder them.</p>
                            <p>This palette also defines the available color backgrounds in your theme (in addition to
                                the specific background colors you can define below).</p>
                            <p>NOTE: Generally, this palette should only contain actual colors and you should avoid
                                adding colors to similar to black or white as they may have odd results on smart
                                slides.</p>
                        </>
                    }>
                        Colors
                        <PredefinedColorPalettePicker update={this.replacePalette} theme={theme} />
                    </SimpleLabel>
                    <ColorChitCollection
                        forceRefresh={forceRefresh}
                        paletteName={theme.palette_name}
                        colors={paletteColors}
                        {...this.props}
                        onUpdate={this.handleUpdateColors}
                    />
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <>
                            <p>Choose the colors for text over both dark and light backgrounds.</p>
                            <p><b>Primary</b> text colors are used for heading, titles, and other important text boxes.
                            </p>
                            <p><b>Secondary</b> text colors are used for body text and other secondary text boxes.</p>
                            <p>NOTE: You should avoid choosing text colors that aren't close to the default black and
                                white as Beautiful.ai expects the text colors to be dark over light backgrounds and
                                light over dark backgrounds. Straying too far from these colors may have odd results in
                                your theme.</p>
                        </>
                    }>
                        Text Colors
                    </SimpleLabel>
                    <FlexBox top left gap={14}>
                        <LabeledOption label="Primary Dark">
                            <ColorChit
                                color={theme.colors.primary_dark}
                                name="primary_dark"
                                onChange={color => this.updateColor("primary_dark", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Primary Light">
                            <ColorChit
                                color={theme.colors.primary_light}
                                name="primary_light"
                                previewBackground="black"
                                onChange={color => this.updateColor("primary_light", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Secondary Dark">
                            <ColorChit
                                color={theme.colors.secondary_dark}
                                name="secondary_dark"
                                onChange={color => this.updateColor("secondary_dark", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Secondary Light">
                            <ColorChit
                                color={theme.colors.secondary_light}
                                name="secondary_light"
                                previewBackground="black"
                                onChange={color => this.updateColor("secondary_light", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Hyperlinks">
                            <ColorChit
                                color={theme.colors.hyperlink}
                                name="hyperlink"
                                previewBackground="black"
                                onChange={color => this.updateColor("hyperlink", color)}
                            />
                        </LabeledOption>
                    </FlexBox>
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <p>The positive and negative colors are used on certain templates like charts and tables to
                            indicate positive and negative values.</p>
                    }
                    >
                        Special Colors
                    </SimpleLabel>
                    <FlexBox middle left gap={20}>
                        <LabeledOption label="Positive">
                            <ColorChit
                                color={theme.colors.positive}
                                name="positive"
                                onChange={color => this.updateColor("positive", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Negative">
                            <ColorChit
                                color={theme.colors.negative}
                                name="negative"
                                onChange={color => this.updateColor("negative", color)}
                            />
                        </LabeledOption>
                    </FlexBox>
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <p>
                            <p>This palette is used specifically for charts. Each series in a chart can pick any of
                                these colors.</p>
                            <p>You can quickly copy your theme palette into the charts colors from the drop down
                                menu.</p>
                        </p>
                    }>
                        Chart Colors
                        <PopupMenu icon="more_vert" style={{ fontSize: 18 }}>
                            <MenuItem onClick={() => this.applyBaseColorsToChartColors()}>
                                <Icon>colorize</Icon>
                                <Gap10 />
                                <span>Apply Palette Colors</span>
                            </MenuItem>
                        </PopupMenu>
                    </SimpleLabel>
                    <div>
                        <ColorChitCollection
                            forceRefresh={forceRefresh}
                            paletteName={theme.palette_name}
                            colors={chartColors}
                            {...this.props}
                            onUpdate={this.handleUpdateChartColors}
                        />
                    </div>
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <>
                            <p>You can define <b>light</b>, <b>dark</b>, and <b>accent colors</b> that will be
                                available
                                for as background colors for most slides (in addition to the colors from your theme
                                palette).</p>
                            <p>NOTE: Generally, you should avoid setting colors that aren't similar to light and
                                dark as
                                Beautiful.ai may make incorrect assumptions when choosing the colors to use for
                                slide
                                text and shapes.</p>

                        </>
                    }>
                        Background Colors
                    </SimpleLabel>
                    <FlexBox middle left gap={20}>
                        <LabeledOption label="Light">
                            <ColorChit
                                color={theme.colors.background_light}
                                name="background_light"
                                onChange={color => this.updateColor("background_light", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Dark">
                            <ColorChit
                                color={theme.colors.background_dark}
                                name="background_dark"
                                onChange={color => this.updateColor("background_dark", color)}
                            />
                        </LabeledOption>
                        <LabeledOption label="Accent">
                            <ColorChit
                                color={theme.colors.background_accent}
                                name="background_accent"
                                onChange={color => this.updateColor("background_accent", color)}
                            />
                        </LabeledOption>
                    </FlexBox>
                </div>

                <div>
                    <SimpleLabel>Background Gradients</SimpleLabel>
                    <ThemeGradients theme={theme} update={this.props.update} />
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <>
                            <p>You can upload one or more images to use as <b>Background Images</b> for your slides.</p>
                            <p>Each image has a setting that tells Beautiful.ai how to treat the background: Light,
                                Dark, or
                                as a Color. Make sure to set this appropriately as Beautiful.ai will use this
                                information to
                                make better choices when picking the colors used on a slide.</p>
                            <p>NOTE: Avoid overly detailed and busy photos to use as background images as they may look
                                odd
                                or incorrect on some smart slide templates.</p>
                        </>
                    }>
                        Background Images
                    </SimpleLabel>
                    <ThemeBackgroundImages theme={theme} update={this.props.update} />
                </div>

                <div>
                    <SimpleLabel tooltip={
                        <p>The <b>Default Background</b> option lets you pick which of your available background
                            colors, theme colors or background images to use by default on new slides.</p>
                    }>
                        Default Background
                    </SimpleLabel>
                    <DefaultBackgroundColor
                        theme={theme}
                        update={this.props.update}
                    />
                </div>

                <div>
                    <ThemeOptions
                        options={backgroundStyle}
                        title="Background Effect"
                        property="styleBackground"
                        selected={theme.styleBackground}
                        update={this.updateBackgroundStyle}
                        theme={theme}
                        tooltip="Sets a background effect for all slides in the presentation."
                    />
                </div>

            </ThemeSettingsPane>
        );
    }
}

