import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Icon, MenuItem } from "@material-ui/core";

import "medium-editor/dist/css/medium-editor.min.css";
import "medium-editor/dist/css/themes/default.min.css";
import MediumEditor from "medium-editor";

import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { handleEditSharedSlide } from "legacy-js/editor/editShareSlide";
import getLogger, { LogGroup } from "js/core/logger";
import { Key } from "js/core/utilities/keys";
import AppController from "legacy-js/core/AppController";
import { app } from "js/namespaces";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { ds } from "js/core/models/dataService";

import DesignerBotIcon from "legacy-js/react/components/DesignerBotIcon";
import { GenerateSlideNoteType } from "legacy-common/aiConstants";
import { FeatureType } from "legacy-common/features";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";
import { generateSlideNotes } from "js/core/utilities/generateSlideNotes";
import {
    ShowDialog, ShowErrorDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import ProgressDialog from "legacy-js/react/components/Dialogs/ProgressDialog";

const logger = getLogger(LogGroup.EDITOR);

const Container = styled.div`
    overflow: unset;
    z-index: 1000;

    width: 100%;
    height: 100%;
    background: #f1f1f1;
    font-size: 14px;
    cursor: default;
    max-width: 100vw;
    max-height: 100vh;
`;

const SpeakerNotesHeader = styled.div`
    height: 32px;
    color: #333;
    padding: 0px 20px;
    background: #f1f1f1;
    text-align: center;
    pointer-events: none;
    display: flex;
    align-items: center;
    position: relative;
`;

const SpeakerNotesTitle = styled.div`
    color: #333;
    font-size: 15px;
    letter-spacing: .9px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 20px;
    margin-right: 20px;
`;

const SpeakerNotesCloseButton = styled.div`
    right: -4px;
    top: 2px;
    color: #333;
    position: absolute;
    pointer-events: auto;
    width: 40px;
    height: 40px;
    cursor: pointer;
    text-align: center;
    padding-top: 5px;
    z-index: 100;
`;

const Contents = styled.div`
    padding: 10px 20px 10px 20px;
    background: #f1f1f1;
    clear: both;
    position: relative;
    overflow: auto;
    height: calc(100% - 40px);
`;

const SpeakerNotesEditorContainer = styled.div`
    overflow: auto;
    width: 100%;
    height: calc(100% - 6px);
    resize: none;
    font-size: 14px;
    color: #333;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px white;
    background: white;
    border-radius: 0;
    cursor: text;

    &:focus {
      border: solid 1px #eee;
      outline: none;
    }

    &.disabled {
      pointer-events: none;
    }
`;

const PopupMenuContainer = styled.div`
    display: block;
    position: absolute;
    background: purple;
    color: white;
    margin-top: 21px;
    height: 32px;
    z-index: 100;
    border-radius: 4px;
    padding-top: 3px;
`;

const MediumEditorToolbarContainer = styled.div`
    position: absolute;
    left: 50%;
    bottom: -10px;
    transform: translateX(-50%);
    z-index: 1000;
    pointer-events: all;

    .medium-editor-toolbar {
        position: relative;
        box-shadow: none;
    }

    .medium-editor-toolbar li button {
        min-width: 35px !important;
        height: 30px !important;
        padding: 0px !important;
        border: none !important;
        
        &:hover{
            color: #29B2E5 !important;
            background-color: #333 !important;
        }
    }

    .medium-editor-button-active {
        color: #29B2E5 !important;
        background-color: #333 !important;
    }

    .medium-editor-action {
        display: flex !important;
        align-items: center;
        justify-content: center;

        i {
            font-size: 18px;
        }
    }
`;

const SpeakerNotes = ({ currentSlide, slides, transitionState, isSingleSlideEditor, isResizing, currentCanvasController, onClose }) => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isMenuClicked, setIsMenuClicked] = useState(false);

    const mediumEditorToolbarContainerRef = useRef(null);
    const mediumEditorContainerRef = useRef(null);
    const mediumEditorRef = useRef(null);
    const saveSlideTimeout = useRef(null);
    const popupRef = useRef(null);

    const isCurrentSlideReadOnly = currentSlide && !isSingleSlideEditor && currentSlide.isLibrarySlide();

    const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
    const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);

    const shouldShowDesignerBot = !designerBotDisabled && designerBotAccessible;

    useEffect(() => {
        if (transitionState !== "entered") {
            return;
        }

        mediumEditorRef.current?.destroy();

        if (isResizing) {
            return;
        }

        setupMediumEditor();
        renderContents();

        return () => {
            savePendingTextImmediately();

            mediumEditorRef.current?.destroy();
        };
    }, [currentSlide, transitionState, isResizing]);

    useEffect(() => {
        const positionPopup = () => {
            const popup = popupRef.current;

            if (popup) {
                const bodyWidth = document.body.clientWidth;
                const bodyMiddleX = bodyWidth / 2;
                popup.style.left = `${bodyMiddleX + 55}px`;
            }
        };

        // Initial positioning
        positionPopup();

        // Recalculate position on window resize
        window.addEventListener("resize", positionPopup);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener("resize", positionPopup);
        };
    }, [transitionState, isResizing, currentSlide]);

    useEffect(() => {
        const handleMouseDown = event => {
            const toolbar = document.querySelector(".medium-editor-toolbar");

            if ((popupRef.current && popupRef.current.contains(event.target)) || (toolbar && toolbar.contains(event.target))) {
                setIsMenuClicked(true);
            } else {
                setIsMenuClicked(false);
            }
        };

        const handleFocus = () => {
            setIsPopupVisible(true);
            setIsMenuClicked(true);
        };

        const handleBlur = () => {
            setTimeout(() => {
                if (!isMenuClicked) {
                    setIsPopupVisible(false);
                }
            }, 100);
        };

        const handleMediumEditorClick = () => {
            setIsMenuClicked(true);
        };

        mediumEditorContainerRef.current.addEventListener("focus", handleFocus);
        mediumEditorContainerRef.current.addEventListener("blur", handleBlur);
        mediumEditorContainerRef.current.addEventListener("click", handleMediumEditorClick);
        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            mediumEditorContainerRef.current.removeEventListener("focus", handleFocus);
            mediumEditorContainerRef.current.removeEventListener("blur", handleBlur);
            mediumEditorContainerRef.current.removeEventListener("click", handleMediumEditorClick);
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, [isMenuClicked]);

    const setupMediumEditor = () => {
        mediumEditorRef.current = new MediumEditor(mediumEditorContainerRef.current, {
            toolbar: {
                buttons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    {
                        name: "unorderedlist",
                        classList: ["norderedlist"]
                    },
                    {
                        name: "orderedlist",
                        classList: ["orderedlist"]
                    }
                ],
                static: true,
                sticky: true,
                updateOnEmptySelection: true,
                relativeContainer: mediumEditorToolbarContainerRef.current
            },
            paste: {
                cleanPastedHTML: true
            },
            placeholder: {
                text: "Type your speaker notes",
                hideOnClick: true
            },
            disableEditing: isCurrentSlideReadOnly
        });
    };

    const scheduleSaveText = () => {
        if (saveSlideTimeout.current) {
            clearTimeout(saveSlideTimeout.current);
        }

        saveSlideTimeout.current = setTimeout(() => {
            saveText();
            saveSlideTimeout.current = null;
        }, 5000);
    };

    const savePendingTextImmediately = () => {
        if (saveSlideTimeout.current) {
            clearTimeout(saveSlideTimeout.current);
            saveSlideTimeout.current = null;

            saveText();
        }
    };

    const saveText = () => {
        currentSlide.update({
            slide_notes: sanitizeHtml(mediumEditorRef.current.getContent(0))
        });
    };

    const subscribeToEvents = () => {
        if (!mediumEditorRef.current) {
            return;
        }

        mediumEditorRef.current.subscribe("editableInput", () => {
            scheduleSaveText();
        });

        mediumEditorRef.current.subscribe("focus", event => {
            event.preventDefault();
            ds.selection.element = null;
            currentCanvasController.lockSlideForCollaborators(120);
        });

        mediumEditorRef.current.subscribe("blur", () => {
            savePendingTextImmediately();

            if (currentCanvasController.isLockedForCollaborators()) {
                currentCanvasController.unlockSlideForCollaborators();
            }
        });
    };

    const renderContents = () => {
        const slideId = currentSlide.id;

        currentSlide.load()
            .then(() => {
                if (!mediumEditorRef.current || currentSlide?.id !== slideId) {
                    // Slide changed or closed, no need to proceed
                    return;
                }

                mediumEditorRef.current.setup();
                mediumEditorRef.current.setContent(sanitizeHtml(currentSlide.get("slide_notes") || ""), 0);

                if (!isCurrentSlideReadOnly) {
                    subscribeToEvents();

                    document.getElementsByClassName("norderedlist")[0].innerHTML = '<i class="micon">format_list_bulleted</i>';
                    document.getElementsByClassName("orderedlist")[0].innerHTML = '<i class="micon">format_list_numbered</i>';

                    setTimeout(() => {
                        mediumEditorRef.current.elements[0].focus();
                    }, 0);
                }
            })
            .catch(err => logger.error(err, "renderContents() failed"));
    };

    const stopPropagation = event => event.stopPropagation();

    const handleKeyDown = event => {
        event.stopPropagation();

        if (event.keyCode === Key.TAB) {
            event.preventDefault();
        }
    };

    const getPreviousSlide = () => {
        const index = slides.findIndex(slide => slide.id === currentSlide.id);
        if (index > 0) {
            return slides[index - 1];
        } else {
            return null;
        }
    };

    const getNextSlide = () => {
        const index = slides.findIndex(slide => slide.id === currentSlide.id);
        if (index < slides.length - 1) {
            return slides[index + 1];
        } else {
            return null;
        }
    };

    const applyGenerateSlideNotesResult = result => {
        let {
            slideNotes,
        } = result;

        slideNotes = slideNotes.replace(/\n/g, "<br/>");

        if (slideNotes.startsWith("•")) {
            slideNotes = slideNotes.replace(/•/g, "<br/>•");

            if (slideNotes.startsWith("<br/>•")) {
                slideNotes = slideNotes.substring(5);
            }
        }

        slideNotes = sanitizeHtml(slideNotes);

        currentSlide.update({ slide_notes: slideNotes });

        mediumEditorRef.current.setContent(slideNotes, 0);
    };

    const generateNotes = async notesType => {
        const dialogProgress = ShowDialog(ProgressDialog, {
            title: "Generating notes...",
        });

        setIsPopupVisible(false);

        const handleError = error => {
            logger.error(error, "Error while performing slide notes generate");
            dialogProgress.props.closeDialog();
            ShowErrorDialog({
                title: (
                    <>Unfortunately, the notes generation failed.<br />Please try again.</>
                ),
            });
        };

        const prevSlideText = getPreviousSlide()?.getSlideText();
        const nextSlideText = getNextSlide()?.getSlideText();
        const prevSlideNotes = getPreviousSlide()?.get("slide_notes");

        await generateSlideNotes({
            notesType,
            slideText: currentSlide.getSlideText(),
            slideIndex: PresentationEditorController.getCurrentSlideIndex() + 1,
            prevSlideText,
            nextSlideText,
            prevSlideNotes,
            onReportState: ({
                results,
                isGenerating,
                error,
            }) => {
                if (error) {
                    handleError(error);
                } else if (
                    !isGenerating &&
                    results.length
                ) {
                    const result = results[0];
                    if (!result?.slideNotes?.length) {
                        handleError("Result text is empty.");
                        return;
                    }

                    applyGenerateSlideNotesResult(result);
                    dialogProgress.props.closeDialog();
                }
            }
        });
    };

    const rewriteSlideNotes = async () => {
        const notesText = currentSlide.get("slide_notes");
        const prevSlideText = getPreviousSlide()?.getSlideText();
        const nextSlideText = getNextSlide()?.getSlideText();
        const prevSlideNotes = getPreviousSlide()?.get("slide_notes");
        if (!notesText) {
            ShowErrorDialog({
                title: (
                    <>There are no notes to rewrite.<br />Please generate new notes or add them manually.</>
                ),
            });
            return;
        }

        const dialogProgress = ShowDialog(ProgressDialog, {
            title: "Generating notes...",
        });

        const handleError = error => {
            logger.error(error, "Error while performing slide notes generate");
            dialogProgress.props.closeDialog();
            ShowErrorDialog({
                title: (
                    <>Unfortunately, the notes generation failed.<br />Please try again.</>
                ),
            });
        };

        const notesType = GenerateSlideNoteType.REWRITE;

        await generateSlideNotes({
            notesType,
            slideText: currentSlide.getSlideText(),
            notesText,
            slideIndex: PresentationEditorController.getCurrentSlideIndex() + 1,
            prevSlideText,
            nextSlideText,
            prevSlideNotes,
            onReportState: ({
                results,
                isGenerating,
                error,
            }) => {
                if (error) {
                    handleError(error);
                } else if (
                    !isGenerating &&
                    results.length
                ) {
                    const result = results[0];
                    if (!result?.slideNotes?.length) {
                        handleError("Result text is empty.");
                        return;
                    }

                    applyGenerateSlideNotesResult(result);
                    dialogProgress.props.closeDialog();
                }
            }
        });
    };

    return (<Container>
        <SpeakerNotesHeader>
            <SpeakerNotesTitle>Speaker Notes</SpeakerNotesTitle>
            <MediumEditorToolbarContainer ref={mediumEditorToolbarContainerRef} />
            {shouldShowDesignerBot && (
                <PopupMenuContainer
                    ref={popupRef}
                    style={{ display: isPopupVisible ? "block" : "none" }}
                >
                    <PopupMenu
                        svgIcon={<DesignerBotIcon />}
                        childrenAreMenuItems
                        disableOverflow
                    >
                        <MenuItem
                            onClick={() => generateNotes(GenerateSlideNoteType.SCRIPT)}
                        >
                            <Icon>comment</Icon>
                            <span>Generate script</span>
                        </MenuItem>
                        <MenuItem
                            onClick={() => generateNotes(GenerateSlideNoteType.BULLET_POINTS)}
                        >
                            <Icon>format_list_bulleted</Icon>
                            <span>Generate bullet points</span>
                        </MenuItem>
                        <MenuItem
                            onClick={() => rewriteSlideNotes()}
                        >
                            <Icon>autorenew</Icon>
                            <span>Rewrite my notes</span>
                        </MenuItem>
                    </PopupMenu>
                </PopupMenuContainer>
            )}
            <SpeakerNotesCloseButton onClick={onClose}>
                <Icon fontSize="small">close</Icon>
            </SpeakerNotesCloseButton>
        </SpeakerNotesHeader>
        <Contents>
            <SpeakerNotesEditorContainer
                onScroll={stopPropagation}
                onKeyDown={handleKeyDown}
                onClick={() => isCurrentSlideReadOnly && handleEditSharedSlide()}
                ref={mediumEditorContainerRef}
            />
        </Contents>
    </Container>);
};

export default PresentationEditorController.withState(SpeakerNotes);
