import React, { Component } from "react";
import styled from "styled-components";

import { Box, FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Key } from "js/core/utilities/keys";
import { Icon } from "./Icon";
import { themeColors } from "../react/sharedStyles";

const Container = styled(Box)`
    display: flex;
    height: 30px;
`;

const StyledInput = styled.input`
    font-size: 13px;
    width: 100%;
    height: 100%;
    outline: none;
    box-sizing: border-box;
    padding: 0px 8px;
    border-radius: 0px;
    border: solid 1px #bbb;
`;

export class Input extends Component {
    render() {
        const { width, disabled, value = "", onChange, ...rest } = this.props;

        return (
            <Container width={width ?? 60}>
                <StyledInput type="text"
                    value={value}
                    onChange={event => onChange(event.target.value)}
                    disabled={disabled}
                    {...rest}
                />
            </Container>
        );
    }
}

