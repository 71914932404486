import React, { Component } from "react";
import { _ } from "legacy-js/vendor";
import styled from "styled-components";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";

import ThemeOption from "./ThemeOption";

const OptionsContainer = styled.div`
    display: flex;
    position: relative;
    margin-left: -6px;
    
    label > span {
        font-size: 10.5px;
        width: 100%;
        left: 0;
        font-weight: 500;
        color: #555;
        padding-left: 0;
        padding-top: 8px;
        display: block;
        white-space: nowrap;
        text-align: center;
    }
`;

export default class ThemeOptions extends Component {
    renderOptions() {
        const { theme, property, update, options } = this.props;

        return options.map((item, index) => {
            // We are mutating the option value here, which can result in unexpected behavior
            // in the Header Alignment prop, because then the value is "none_left" instead of "left"
            // and the Header Alignment prop is expecting "left" as the value.
            const option = _.clone(item);
            switch (option.value) {
                case "none_left":
                case "underline_left":
                case "bar_top_left":
                case "block_left": {
                    const selected = theme[property].includes(
                        option.value.replace("_left", "")
                    );
                    return (
                        <ThemeOption
                            update={update}
                            item={option}
                            selected={selected}
                            property={property}
                            key={index}
                        />
                    );
                }
                case "left":
                case "center": {
                    let disabled = ["bar_left"].includes(theme.styleDecoration);
                    const Delimiter = theme.styleDecoration.lastIndexOf("_");
                    const value =
                        theme.styleDecoration.substring(
                            0,
                            Delimiter < 0
                                ? theme.styleDecoration.length
                                : Delimiter
                        ) +
                        "_" +
                        option.value;

                    option.value = value;
                    return (
                        <ThemeOption
                            update={update}
                            item={option}
                            selected={theme[property] === value && !disabled}
                            property={property}
                            disabled={disabled}
                            key={index}
                        />
                    );
                }
                default:
                    return (
                        <ThemeOption
                            update={update}
                            item={option}
                            selected={theme[property] === item.value}
                            property={property}
                            key={index}
                        />
                    );
            }
        });
    }
    render() {
        const { title, className, tooltip } = this.props;
        return (
            <div className={className}>
                <SimpleLabel tooltip={tooltip}>
                    {title}
                </SimpleLabel>
                <OptionsContainer>
                    {this.renderOptions()}
                </OptionsContainer>
            </div>
        );
    }
}
