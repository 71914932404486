import React from "react";
import ThemeOptions from "legacy-js/editor/ThemeEditor/components/ThemeOptions";
import IconOptions from "legacy-js/editor/ThemeEditor/components/Shapes/IconOptions";
import styled from "styled-components";
import { getStaticUrl } from "legacy-js/config";
import { ThemeSettingsPane } from "legacy-js/editor/ThemeEditor/components/ThemeSettingsPane";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";

const InlineThemeOptions = styled(ThemeOptions)`
    display: inline-block;
    margin-right: 20px;
`;

export const shapeStyle = [
    {
        value: "outlined",
        label: "Outline",
        icon: <img src={getStaticUrl("/images/theme_editor/Shape-Outline.svg")} />
    },
    {
        value: "muted",
        label: "Muted",
        icon: <img src={getStaticUrl("/images/theme_editor/Shape-Muted.svg")} />
    },
    {
        value: "filled",
        label: "Filled",
        icon: <img src={getStaticUrl("/images/theme_editor/Shape-Filled.svg")} />
    },
    {
        value: "fillAndStroke",
        label: "Fill & Stroke",
        icon: <img src={getStaticUrl("/images/theme_editor/Shape-Fill-Stroke.svg")} />
    }
];

const ThemeDecoration = props => {
    let {
        theme,
        iconBaseUrl,
        update,
    } = props;

    const headerStyle = [
        {
            value: "none_left",
            label: "Plain",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-None.svg")} />
        },
        {
            value: "underline_left",
            label: "Underline",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Underline.svg")} />
        },
        {
            value: "bar_left",
            label: "Left Bar",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Left-Bar.svg")} />
        },
        {
            value: "bar_top_left",
            label: "Top Bar",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Top-Bar.svg")} />
        },
        {
            value: "block_left",
            label: "Block",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Block.svg")} />
        }
    ];

    const headerAlignment = [
        {
            value: "left",
            label: "Left",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Align-Left.svg")} />
        },
        {
            value: "center",
            label: "center",
            icon: <img src={getStaticUrl("/images/theme_editor/Header-Align-Center.svg")} />
        }
    ];

    const shapeType = [
        {
            value: "none",
            label: "None",
            icon: <img src={getStaticUrl("/images/theme_editor/Shape-None.svg")} />
        },
        {
            value: "circle",
            label: "Circle",
            icon: <img src={getStaticUrl("/images/theme_editor/Shape-Circle.svg")} />
        },
        {
            value: "rect",
            label: "Square",
            icon: <img src={getStaticUrl("/images/theme_editor/Shape-Rectangle.svg")} />
        },
        {
            value: "rounded_rect",
            label: "Rounded",
            icon: <img src={getStaticUrl("/images/theme_editor/Shape-Rounded.svg")} />
        },
        {
            value: "octagon",
            label: "Octagon",
            icon: <img src={getStaticUrl("/images/theme_editor/Shape-Octagon.svg")} />
        }
    ];

    const lineWeight = [
        {
            value: "light",
            label: "Light",
            icon: <img src={getStaticUrl("/images/theme_editor/Line-Light.svg")} />
        },
        {
            value: "medium",
            label: "Medium",
            icon: <img src={getStaticUrl("/images/theme_editor/Line-Medium.svg")} />
        },
        {
            value: "heavy",
            label: "Heavy",
            icon: <img src={getStaticUrl("/images/theme_editor/Line-Heavy.svg")} />
        }
    ];

    const effect = [
        {
            value: "none",
            label: "None",
            icon: <img src={getStaticUrl("/images/theme_editor/Effect-None.svg")} />
        },
        {
            value: "shadow",
            label: "Shadow",
            icon: <img src={getStaticUrl("/images/theme_editor/Effect-Shadow.svg")} />
        }
    ];

    return (
        <ThemeSettingsPane>
            <ThemeOptions
                options={headerStyle}
                title="Header Style"
                property="styleDecoration"
                selected={theme.styleDecoration}
                update={update}
                theme={theme}
                tooltip={
                    <p>Sets the style of the header on a slide</p>
                }
            />
            <ThemeOptions
                options={headerAlignment}
                title="Header Alignment"
                property="styleDecoration"
                selected={theme.styleDecoration}
                update={update}
                theme={theme}
                tooltip={
                    <p>Sets the alignment of the header text on a slide</p>
                }
            />
            <div>
                <SimpleLabel tooltip="Sets the style of icons available when adding an icon to a slide.">Icon Style</SimpleLabel>
                <IconOptions
                    update={update}
                    theme={theme}
                    iconBaseUrl={iconBaseUrl}
                />
            </div>
            <ThemeOptions
                options={shapeType}
                title="Shape Type"
                property="styleShape"
                update={update}
                theme={theme}
                tooltip="Sets the default frame shape used on many slide templates."
            />
            <ThemeOptions
                options={shapeStyle}
                title="Shape Style"
                property="styleElementStyle"
                update={update}
                theme={theme}
                tooltip="Sets the default styling for decoration styles on shapes."
            />
            <div>
                <InlineThemeOptions
                    options={lineWeight}
                    title="Line Weight"
                    property="styleWeight"
                    update={update}
                    theme={theme}
                    tooltip="Sets the line weight for connectors, outlines and other paths."
                />
                <InlineThemeOptions
                    options={effect}
                    title="Effect"
                    property="styleEffect"
                    update={update}
                    theme={theme}
                    tooltip="Sets whether various elements should show a drop shadow effect on slides."
                />
            </div>
        </ThemeSettingsPane>
    );
};

export default ThemeDecoration;
