import React, { Component } from "react";
import styled from "styled-components";
import { Icon, Popover, InputAdornment, TextField, IconButton } from "@material-ui/core";
import { PREDEFINED_PALETTES } from "legacy-common/constants";
import { OptionsMenu } from "legacy-js/react/components/OptionsMenu";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";

const ColorPaletteContainer = styled.div`
  width: 135px;
  margin-right: 36px;
  margin-bottom: 12px;
  display: inline-block;
`;

const ColorPaletteLabel = styled.label`
  text-transform: uppercase;
  color: #333;
  display: block;
  margin-bottom: 6px;
`;

const ColorPaletteColor = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

export default class PredefinedColorPalettePicker extends Component {
    updatePalette = paletteIndex => {
        const palette = PREDEFINED_PALETTES[paletteIndex];
        const updateObj = {
            palette_name: palette.id,
            colors: palette.colors
        };
        updateObj.defaultBackgroundColor = [
            "gradient",
            "reverseGradient"
        ].includes(this.props.theme.styleBackground)
            ? "theme"
            : "background_light";
        this.props.update(updateObj);
        this.setState({ anchorEl: null });
    };

    getPaletteName = paletteId => {
        const selectedPalette = PREDEFINED_PALETTES.filter(palette => {
            return palette.id === paletteId;
        });

        const paletteName = selectedPalette[0]
            ? selectedPalette[0].name
            : "Custom";
        return paletteName;
    };

    render() {
        return (
            <PopupMenu showArrow icon="palette" iconStyle={{ fontSize: 15, marginRight: -2 }}>
                {closePopup => {
                    return (
                        <div style={{ padding: "24px 40px", width: 770 }}>
                            {PREDEFINED_PALETTES.map((palette, index) => {
                                const colors = Object.keys(palette.colors);
                                return (
                                    <ColorPaletteContainer
                                        key={index}
                                        onClick={() => {
                                            this.updatePalette(index);
                                            closePopup();
                                        }}
                                    >
                                        <ColorPaletteLabel>
                                            {palette.name}
                                        </ColorPaletteLabel>
                                        <div>
                                            <ColorPaletteColor
                                                style={{ backgroundColor: palette.colors.theme }}
                                            />
                                            {colors.map((color, index) => {
                                                if (color.startsWith("accent")) {
                                                    return (
                                                        <ColorPaletteColor
                                                            key={index}
                                                            style={{
                                                                backgroundColor:
                                                                    palette.colors[color]
                                                            }}
                                                        />
                                                    );
                                                }
                                            })}
                                        </div>
                                    </ColorPaletteContainer>
                                );
                            }) }
                        </div>
                    );
                }}
            </PopupMenu>
        );
    }
}
