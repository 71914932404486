import React, { Component } from "react";
import styled from "styled-components";

import { IconButton } from "@material-ui/core";
import {
    Close as CloseIcon,
    Mic as MicIcon
} from "@material-ui/icons";

import { BlueButton } from "legacy-js/react/components/UiComponents";

import { FlexSpacer, Gap20 } from "legacy-js/react/components/Gap";
import { AudioEditor } from "./Components/AudioEditor/AudioEditor";
import { BoxButton } from "legacy-js/react/views/AnimationPanel/Components/BoxButton";
import { PositionType, SlideRecordingType } from "legacy-common/constants";
import { ds } from "js/core/models/dataService";
import { ShowConfirmationDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { themeColors } from "legacy-js/react/sharedStyles";
import { VideoOverlayPanel } from "./Components/VideoOverlay/VideoOverlayPanel";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";

export const AnimationPanelTabType = {
    ANIMATION: "animation",
    AUDIO: "audio"
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
`;

const Header = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding-right: 10px;

  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #666666;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  padding: 10px;
  color: #333;
  position: relative;
`;

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PreviewButtonContainer = styled.div`
  width: 100%;
  padding: 10px;
`;

const PreviewButton = styled(BlueButton)`
  &&& {
    height: 40px;
    pointer-events: auto;
    border-radius: 0;
  }
`;

const Message = styled.div`
  font-size: 12px;
  color: ${themeColors.darkGray};
`;

class RecordPanel extends Component {
    constructor(props) {
        super(props);

        const canvas = this.props.currentCanvasController.canvas;

        let type = SlideRecordingType.NONE;
        if (this.hasAudio(canvas)) {
            type = SlideRecordingType.AUDIO;
        } else if (this.hasVideo(canvas)) {
            type = SlideRecordingType.VIDEO;
        }

        this.state = {
            slideRecordingType: type
        };
    }

    hasAudio = canvas => {
        return canvas.dataModel.has("audioAsset");
    }

    hasVideo = canvas => {
        return canvas.layouter.elements.annotations.model.videoOverlay != null;
    }

    setSlideRecordingType = async type => {
        const canvas = this.props.currentCanvasController.canvas;

        switch (type) {
            case SlideRecordingType.NONE:
                if (this.hasAudio(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the audio track currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }
                if (this.hasVideo(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the video bubble currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }

                canvas.dataModel.update({
                    audioAsset: null
                });
                canvas.layouter.elements.annotations.model.videoOverlay = null;
                canvas.updateCanvasModel();

                this.setState({ slideRecordingType: SlideRecordingType.NONE });
                break;
            case SlideRecordingType.AUDIO:
                if (this.hasVideo(canvas)) {
                    if (!(await ShowConfirmationDialog({
                        title: "Do you want to remove the video bubble currently assigned to this slide?"
                    }))) {
                        return;
                    }
                }

                canvas.layouter.elements.annotations.model.videoOverlay = null;
                canvas.updateCanvasModel();

                this.setState({ slideRecordingType: SlideRecordingType.AUDIO });
                break;
            case SlideRecordingType.VIDEO:
                if (this.hasAudio(canvas)) {
                    if (await ShowConfirmationDialog({
                        title: "Do you want to remove the audio track currently assigned to this slide?"
                    })) {
                        canvas.dataModel.update({
                            audioAsset: null
                        });
                    } else {
                        return;
                    }
                }

                if (!this.hasVideo(canvas)) {
                    canvas.layouter.elements.annotations.model.videoOverlay = {
                        x: 0.015625,
                        y: 0.694444444444,
                        videoAssetId: ""
                    };
                    await canvas.updateCanvasModel(false);
                    canvas.layouter.elements.annotations.videoOverlay?.setPosition(PositionType.BOTTOM_LEFT, false);
                }

                ds.selection.element = canvas.layouter.elements.annotations.videoOverlay;

                this.setState({ slideRecordingType: SlideRecordingType.VIDEO });
                break;
        }
    }

    render() {
        let { onClose, isReadOnly, currentCanvasController } = this.props;
        let { slideRecordingType } = this.state;

        const canvas = currentCanvasController.canvas;

        let recordingOptions = [{ selected: slideRecordingType === SlideRecordingType.NONE,
            onClick: () => this.setSlideRecordingType(SlideRecordingType.NONE),
            IconType: CloseIcon,
            labelText: "None",
            width: "calc((100% - 10px) / 3)",
            containerStyle: { marginRight: "5px" } }, {
            selected: slideRecordingType === SlideRecordingType.AUDIO,
            onClick: () => this.setSlideRecordingType(SlideRecordingType.AUDIO),
            IconType: MicIcon,
            labelText: "Audio",
            width: "calc((100% - 10px) / 3)",
            containerStyle: { marginRight: "5px" }
        }, {
            selected: slideRecordingType === SlideRecordingType.VIDEO,
            onClick: () => {
                this.setSlideRecordingType(SlideRecordingType.VIDEO);
                onClose();
            },
            // There is no 'video_camera_front' icon within material ui
            icon: "video_camera_front",
            labelText: "Video",
            width: "calc((100% - 10px) / 3)"
        }];

        return (
            <Container>
                <Header>
                    Record
                    <FlexSpacer/>
                    <IconButton size="small" onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </Header>

                <ContentContainer>
                    <ControlsContainer>
                        {recordingOptions
                            .map((option, index) => (<BoxButton
                                key={index} {...option}/>))}
                    </ControlsContainer>
                    <Gap20/>

                    {slideRecordingType === SlideRecordingType.NONE &&
                        <Message>No recording.</Message>
                    }

                    {slideRecordingType === SlideRecordingType.AUDIO &&
                        <>
                            <Message>Record or upload audio narration.</Message>
                            <Gap20/>
                            <AudioEditor
                                canvas={canvas}
                                isReadOnly={isReadOnly}
                                onClose={onClose}
                            />
                        </>
                    }

                    {slideRecordingType === SlideRecordingType.VIDEO &&
                    <Message>Record and format video bubble on slide. <VideoOverlayPanel
                        canvas={canvas}
                        onClose={onClose}
                    /></Message>
                    }
                </ContentContainer>
            </Container>
        );
    }
}

export default PresentationEditorController.withState(RecordPanel);
