import React, { Component } from "react";
import { Popover, Slider } from "@material-ui/core";
import styled from "styled-components";

import { app } from "js/namespaces";
import { OptionsMenu } from "legacy-js/react/components/OptionsMenu";

import TextTransform from "./TextTransform";

const FontSliderContainer = styled.div`
    margin-bottom: 20px;

    label {
        text-transform: uppercase;
    }
`;

const FontSettingsContainer = styled.div`
    button {
        height: 100%;
        margin-right: 16px;
    }
`;

const FontSettingsPopover = styled(Popover)`
    &&& {
        > .popover-content {
            padding: 20px;
            width: 250px;
        }
    }
`;

class FontSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: this.props.value
        };
    }
    handleChange = (event, val) => {
        this.setState({ value: val });
    };

    render() {
        const {
            name,
            label,
            min,
            max,
            step,
            unit,
            update,
        } = this.props;

        return (
            <FontSliderContainer>
                <label>{label}</label>
                <div>
                    <Slider
                        style={{ width: "75%", display: "inline-block" }}
                        value={this.state.value}
                        min={min}
                        max={max}
                        step={step}
                        aria-labelledby={label}
                        onChange={this.handleChange}
                        onChangeCommitted={(event, value) => {
                            update({ [name]: value });
                        }}
                    />
                    <span
                        style={{
                            padding: 6,
                            fontSize: 16,
                            position: "relative",
                            top: 4,
                            left: 6
                        }}
                    >
                        {(decimalArr =>
                            decimalArr[1]
                                ? this.state.value.toFixed(decimalArr[1].length)
                                : this.state.value)(step.toString().split("."))}{" "}
                        {unit && unit}
                    </span>
                </div>
            </FontSliderContainer>
        );
    }
}

export default class FontSettings extends Component {
    constructor() {
        super();

        this.state = {
            anchorEl: null,
            selectedIndex: 1,
            fontFaces: []
        };
    }

    handleClickListItem = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {
            style,
            theme,
            update,
        } = this.props;

        return (
            <FontSettingsContainer>
                <OptionsMenu onClick={this.handleClickListItem}/>
                <FontSettingsPopover
                    classes={{ paper: "popover-content" }}
                    disableEnforceFocus
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    anchorEl={this.state.anchorEl}
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <div>
                        <FontSlider
                            min={-0.1}
                            max={0.3}
                            step={0.01}
                            value={theme[`font${style}LetterSpacing`]}
                            name={`font${style}LetterSpacing`}
                            label="Letter Spacing"
                            update={value => {
                                app.wordWidthCache = {};
                                update(value);
                            }}
                        />
                        <FontSlider
                            min={1}
                            max={3}
                            step={0.1}
                            value={theme[`font${style}LineHeight`]}
                            name={`font${style}LineHeight`}
                            label="Line Height"
                            update={update}
                        />
                        <FontSlider
                            min={50}
                            max={150}
                            step={1}
                            value={theme[`font${style}Scaling`]}
                            name={`font${style}Scaling`}
                            label="Font Scale"
                            unit="%"
                            update={update}
                        />

                        <TextTransform
                            label="Case"
                            values={[
                                { label: "Auto", value: "auto" },
                                { label: "Uppercase", value: "uppercase" },
                                { label: "Lowercase", value: "lowercase" }
                            ]}
                            selected={theme[`font${style}TextTransform`]}
                            name={`font${style}TextTransform`}
                            update={update}
                        />
                    </div>
                </FontSettingsPopover>
            </FontSettingsContainer>
        );
    }
}
