import React, { Fragment, Component } from "react";
import styled from "styled-components";
import { ShowErrorDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import { FlexBox, ScrollBox } from "legacy-js/react/components/LayoutGrid";
import { Icon } from "@material-ui/core";
import "css/slide-template-info.scss";
import { Gap20 } from "legacy-js/react/components/Gap";
import {
    BlueButton,
    UIContainer,
    UINavigation,
    WhiteTextButton
} from "legacy-js/react/components/UiComponents";
import { Thumbnail, ThumbnailContainer } from "legacy-js/react/views/AddSlide/Panes/Components/ThumbnailGrid";
import { _ } from "legacy-js/vendor";
import { getStaticUrl } from "legacy-js/config";
import Spinner from "legacy-js/react/components/Spinner";
import Thumbnails from "js/core/models/thumbnails";
import { themeColors } from "legacy-js/react/sharedStyles";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

export class SlideTemplateInfoPage extends Component {
    state = {
        html: null
    }

    async componentDidMount() {
        const { templateId } = this.props;

        const response = await fetch(getStaticUrl(`/template-info/${templateId}.js`));

        if (response.status == 200) {
            const results = await response.text();

            const o = Function(results)();
            this.setState(o);
        } else {
            ShowErrorDialog({
                title: `Template Info page for ${templateId} template does not exist.`
            });
            this.props.closeDialog();
        }
    }

    handleCopySlide = slideId => {
        const { onCopySlide, closeDialog } = this.props;

        onCopySlide(slideId);
        closeDialog();
    }

    handleUseTemplate = () => {
        const { onUseTemplate, closeDialog } = this.props;

        onUseTemplate();
        closeDialog();
    }

    handleClickTip = tip => {
        window.open(tip, "_blank");
    }

    render() {
        const { isLibrary, closeDialog } = this.props;
        const { templateName, html, slides, tips } = this.state;

        if (!templateName) {
            return <Spinner />;
        }

        return (
            <UIContainer isOpen={true} className="add-slide-container" onClose={closeDialog}
                direction="vertical">
                <UINavigation
                    onBack={closeDialog}
                >
                    <Gap20 />
                    <WhiteTextButton type="text" onClick={closeDialog}>{isLibrary ? "Return to Slide Library" : "Return to Slide"}</WhiteTextButton>
                </UINavigation>

                <ScrollBox className="slide-template-info" fillWidth fillHeight>

                    <section>
                        <div className="inner-section">

                            <div className="two-columns">
                                <div className="preview-column">
                                    <img
                                        src="https://global-uploads.webflow.com/59e16042ec229e00016d3a66/5e7b239304c533d33a8f9ad1_headline%20demo%20GIF.gif" />
                                    <Gap20 />
                                </div>

                                <div>
                                    <div className="title">{templateName}</div>
                                    <Gap20 />
                                    <div className="description-text"
                                        dangerouslySetInnerHTML={{ __html: sanitizeHtmlText(html) }}>

                                    </div>
                                    {isLibrary &&
                                        <Fragment>
                                            <Gap20 />
                                            <BlueButton onClick={this.handleUseTemplate}>Use this Template</BlueButton>
                                        </Fragment>
                                    }
                                </div>
                            </div>

                        </div>
                    </section>

                    {slides.length > 0 &&
                        <section className="alt">
                            <div className="inner-section">
                                <h2>
                                    Inspiration to get you started with the {templateName} template
                                </h2>
                                <Gap20 />

                                <div className="inspiration-thumbnails">
                                    {slides.map(item => (<InspirationThumbnail
                                        key={item}
                                        onClick={() => this.handleCopySlide(item)}
                                        item={item}
                                    />))}
                                </div>
                            </div>
                        </section>
                    }

                    {tips.length > 0 &&
                        <section>
                            <div className="inner-section">
                                <h2>
                                    Pro tips for working with the {templateName} template
                                </h2>
                                <Gap20 />

                                <div className="tips">
                                    {tips.map(tip => (<FlexBox onClick={() => this.handleClickTip(tip.link)} className="tip">
                                        <Icon>{tip.icon}</Icon>
                                        {tip.text}
                                    </FlexBox>))}
                                </div>
                            </div>
                        </section>
                    }

                </ScrollBox>
            </UIContainer>
        );
    }
}

const InspirationThumbnailContainer = styled(ThumbnailContainer)`
  .copy-slide-button {
    opacity: 0;
    transition: 300ms;
    position: absolute;
    top: 90px;
    padding: 10px 14px;
    background: ${themeColors.ui_blue};
    color: white;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    pointer-events: none;
  }
  &:hover {
    .copy-slide-button {
      opacity: 1;
    }
  }
`;

class InspirationThumbnail extends Component {
    state = {
        url: "",
    }

    async componentDidMount() {
        const { item } = this.props;
        const url = await Thumbnails.getSignedUrlAndLoad(item);
        this.setState({ url });
    }

    render() {
        const { onClick } = this.props;
        const { url } = this.state;

        return (
            <InspirationThumbnailContainer>
                <Thumbnail url={url}
                    onClick={onClick}
                    style={{ border: "solid 1px #ccc", boxShadow: "0px 0px 20px rgba(0,0,0,0.15)" }} />
                <div className="copy-slide-button">Copy this slide</div>
            </InspirationThumbnailContainer>
        );
    }
}
