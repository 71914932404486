import React, { Component, createRef } from "react";
import { v4 as uuid } from "uuid";

import ColorChit from "legacy-js/editor/ThemeEditor/components/Color/ColorChit";
import styled from "styled-components";
import { $, _ } from "legacy-js/vendor";
import { themeColors } from "legacy-js/react/sharedStyles";
import { Icon } from "@material-ui/core";

const DragColorChit = styled.div`
  display: inline-block;
  float: left;
`;

const AddColorButton = styled.div`
    width: 24px;
    height: 24px;
    float: left;
    border-radius: 50%;
    border: 2px solid ${themeColors.ui_blue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4px;
    margin-left: 4px;
    &:hover {
        background: ${themeColors.ui_blue};
        & .material-icons {
            color: white
        }
    }
    & .material-icons {
        color: ${themeColors.ui_blue};
        font-weight: 800;
       font-size: 16px;
    }
`;

const ColorChitContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export class ColorChitCollection extends Component {
    constructor(props) {
        super();

        this.ref = createRef();
        this.state = {
            colors: this.mapColorsToObjects(props.colors),
            isColorDragging: false
        };
    }

    mapColorsToObjects(colors) {
        return colors.map((color, index) => ({
            id: this.state?.colors?.[index]?.id ?? uuid(),
            color: color
        }));
    }

    componentDidMount() {
        let $el = $(this.ref.current);

        $el.sortable({
            items: ".drag-chit",
            start: event => this.setState({ isColorDragging: true }),
            stop: (event, ui) => {
                this.setState({
                    colors: _.map($el.find(".drag-chit"), chit => {
                        return { id: $(chit).attr("data-id"), color: $(chit).attr("data-color") };
                    }),
                    isColorDragging: false
                });

                this.saveColors();
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { paletteName, forceRefresh, colors } = this.props;
        const { paletteName: prevPlatteName, forceRefresh: prevForceRefresh, colors: prevColors } = prevProps;

        if (
            prevPlatteName !== paletteName ||
            (
                forceRefresh &&
                prevForceRefresh !== forceRefresh
            )
        ) {
            this.setState({ colors: this.mapColorsToObjects(colors) });
        }
    }

    handleAddColor = () => {
        const { colors } = this.state;
        colors.push({
            id: uuid(),
            color: "#ffffff",
            isNewColor: true
        });
        this.setState({
            colors
        });

        this.saveColors();
    }

    handleChangeColor = (index, color) => {
        const { colors } = this.state;
        colors[index].color = color;
        colors[index].isNewColor = false;
        this.setState({ colors });

        this.saveColors();
    }

    handleRemoveColor = index => {
        const { colors } = this.state;
        colors.remove(colors[index]);
        this.setState({ colors });

        this.saveColors();
    }

    saveColors() {
        const { colors } = this.state;
        const { onUpdate } = this.props;
        if (onUpdate) {
            onUpdate(colors.map(c => c.color));
        }
    }

    render() {
        const { colors, isColorDragging } = this.state;

        return (
            <ColorChitContainer ref={this.ref}>
                {colors.map((color, index) =>
                    (<DragColorChit key={color.id} className="drag-chit" data-color={color.color} data-id={color.id}>
                        <ColorChit
                            isNewColor={color?.isNewColor ?? false}
                            color={color.color}
                            onChange={color => this.handleChangeColor(index, color)}
                            onRemove={() => this.handleRemoveColor(index)}
                        />
                    </DragColorChit>)
                )}
                {colors.length < 10 && !isColorDragging && (
                    <AddColorButton id="add-color" onClick={this.handleAddColor}>
                        <Icon color="primary">add</Icon>
                    </AddColorButton>
                )}
            </ColorChitContainer>
        );
    }
}
