import React, { Component } from "react";

import { Slider as MaterialSlider } from "@material-ui/core";

import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap, Gap10 } from "legacy-js/react/components/Gap";

import { NumericStepper } from "./NumericStepper";
import styled from "styled-components";

const SliderContainer = styled.div`
    flex-grow: 2;

    .MuiSlider-markLabel {
        font-size: 10px;
    }

    .MuiSlider-valueLabel {
        span {
            span {
                color: white;
                font-weight: bold;
            }
        }
    }
`;

export class Slider extends Component {
    constructor(props) {
        super(props);

        this.isDragging = false;
    }

    handleSliderChange = (event, value) => {
        const { onChange = () => { }, onDragStart = () => { } } = this.props;
        if (!this.isDragging) {
            this.isDragging = true;
            onDragStart();
        }
        onChange(value);
    }

    handleSliderChangeCommitted = (event, value) => {
        const { onCommit = () => { } } = this.props;
        this.isDragging = false;
        onCommit(value);
    }

    handleNumericStepperChange = value => {
        const { onChange = () => { }, onCommit } = this.props;
        if (onCommit) {
            onCommit(value);
        } else {
            onChange(value);
        }
    }

    render() {
        const { value, onChange = () => { }, onCommit = () => { }, showInput, marks, percent, decimals, min, max, step, width, fillWidth, ...rest } = this.props;

        const format = percent ? "percent" : "number";

        return (
            <FlexBox middle className="bai-slider" width={width} fillWidth={fillWidth}>
                <SliderContainer>
                    <MaterialSlider
                        {...rest}
                        value={value}
                        onChange={this.handleSliderChange}
                        onChangeCommitted={this.handleSliderChangeCommitted}
                        format={format}
                        decimals={decimals}
                        min={min}
                        max={max}
                        step={step}
                        marks={marks}
                    />
                </SliderContainer>
                {showInput && <>
                    <Gap size={15} />
                    <NumericStepper
                        value={value}
                        onChange={this.handleNumericStepperChange}
                        format={format}
                        decimals={decimals}
                        min={min}
                        max={max}
                        step={step}
                    />
                </>}
            </FlexBox>
        );
    }
}

