import React, { Component } from "react";
import moment from "moment";
import { Icon, MenuItem } from "@material-ui/core";

import { _ } from "legacy-js/vendor";
import getUserProfile from "js/core/services/userProfiles";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import Avatar from "legacy-js/react/components/Avatar";
import { FlexSpacer, Gap10, Gap5 } from "legacy-js/react/components/Gap";
import { PopupMenu } from "legacy-js/react/components/PopupMenu";
import styled from "styled-components";
import { themeColors } from "legacy-js/react/sharedStyles";

export const RevisionItemContainer = styled.div`
  padding: 10px 4px 10px 10px;
  width: 100%;
  background: ${props => props.isCurrent ? themeColors.hoverBlue : "none"};

  .avatar {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }

  label {
    letter-spacing: 0px;
  }

  .time {
    font-weight: ${props => props.isCurrent ? 600 : 500};
    font-size: 15px;
    color: black;
  }

  .hint {
    margin-top: 8px;
    background: #d7e6ec;
    text-transform: uppercase;
    color: #333;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin-right: 10px;
  }

  .MuiIcon-root {
    color: ${themeColors.ui_blue};
  }
`;

export class RevisionItem extends Component {
    state = {
        photoUrl: null,
        userName: "",
        isShowingPopup: false
    }

    componentDidMount() {
        const { revision: { userId } } = this.props;

        getUserProfile(userId).then(user => {
            this.setState({
                photoUrl: user.photoURL,
                userName: user.displayName || user.email
            });
        });
    }

    handleMouseEnter = () => {
        const { onPreviewRevision, revision } = this.props;
        onPreviewRevision(revision);
    }

    render() {
        const { revision, onSaveRevision, onCopyRevision, currentRevision } = this.props;
        const { photoUrl, userName, isRollover } = this.state;

        const isCurrent = currentRevision === revision;

        return (
            <RevisionItemContainer
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={() => onSaveRevision(revision)}
                isCurrent={isCurrent}
            >
                <FlexBox left fill>
                    <FlexBox left top vertical>
                        <label className="time">{moment(revision.timestamp).format("MMM D")}, {revision.time}</label>
                        <Gap5 />
                        <FlexBox left middle>
                            <Avatar url={photoUrl} />
                            <label className="username">{userName}</label>
                        </FlexBox>
                    </FlexBox>
                    <FlexSpacer />
                    {isCurrent &&
                        <PopupMenu icon="more_vert"
                            childrenAreMenuItems
                            style={{ padding: 0 }}
                            onShow={() => this.setState({ isShowingPopup: true })}
                            onClose={() => this.setState({ isShowingPopup: false })}
                        >
                            <MenuItem onClick={() => onSaveRevision(revision)} divider>
                                <Icon>history</Icon><Gap10 />
                                Restore this Version
                            </MenuItem>
                            <MenuItem onClick={() => onCopyRevision(revision)}>
                                <Icon>content_copy</Icon><Gap10 />
                                Copy this Version to a New Slide
                            </MenuItem>
                        </PopupMenu>
                    }
                </FlexBox>

                {revision.hint && <div className="hint">{revision.hint}</div>}
            </RevisionItemContainer>
        );
    }
}
