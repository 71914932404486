import React, { Component, Fragment } from "react";
import { ArrowRight } from "@material-ui/icons";
import styled, { css } from "styled-components";

import { _ } from "legacy-js/vendor";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { Gap, Gap30 } from "legacy-js/react/components/Gap";

import { RevisionItem } from "./RevisionItem";

const RevisionGroupContainer = styled.div`

  hr {
    background: #f1f1f1;
    margin: 0px;
  }
  .expander {
    .MuiSvgIcon-root {
      font-size: 28px;
      margin: 7px -5px;
      transition: transform 300ms;
    }

    &.expanded {
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
    }
  }
`;

export class RevisionGroup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: props.expanded ?? false
        };
    }

    handleToggleExpand = () => {
        this.setState(({ expanded }) => ({ expanded: !expanded }));
    }

    render() {
        const { revisionGroup, currentSlide, onPreviewRevision, onSaveRevision, onCopyRevision, currentRevision } = this.props;
        const { expanded } = this.state;

        return (
            <RevisionGroupContainer>
                <FlexBox left middle>
                    {revisionGroup.length > 1 &&
                        <div className={`expander ${expanded ? "expanded" : "collapsed"}`}
                            onClick={this.handleToggleExpand}>
                            <ArrowRight />
                        </div>
                    }
                    {revisionGroup.length === 1 &&
                        <Gap size={18} />
                    }
                    <RevisionItem key={revisionGroup[0].id}
                        currentSlide={currentSlide}
                        revision={revisionGroup[0]}
                        onPreviewRevision={onPreviewRevision}
                        onSaveRevision={onSaveRevision}
                        onCopyRevision={onCopyRevision}
                        currentRevision={currentRevision}
                    />
                </FlexBox>
                <hr />
                {expanded &&
                    _.drop(revisionGroup, 1).map((revision, index) => (
                        <Fragment key={index}>
                            <FlexBox left >
                                <Gap30 />
                                <RevisionItem key={revision.id}
                                    currentSlide={currentSlide}
                                    revision={revision}
                                    onPreviewRevision={onPreviewRevision}
                                    onSaveRevision={onSaveRevision}
                                    onCopyRevision={onCopyRevision}
                                    currentRevision={currentRevision}
                                />
                            </FlexBox>
                            <hr />
                        </Fragment>
                    ))
                }
            </RevisionGroupContainer>
        );
    }
}
