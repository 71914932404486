import React from "react";
import styled from "styled-components";

import { Button } from "@material-ui/core";

import { getStaticUrl } from "legacy-js/config";
import { BeautifulDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

const ContentContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;

    padding: 40px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    color: #222222;
    text-align: center;
`;

const SmartSlidesImage = styled.img`
    width: 350px;
    height: 227px;
`;

const Header = styled.div`
    margin-top: 15px;

    font-weight: 600;
    font-size: 23px;
    line-height: 120%;   
    letter-spacing: 0.5px;
`;

const Subheader = styled.div`  
    margin-top: 15px;

    font-size: 16px;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const ButtonContainer = styled.div`
    width: 100%;
    margin-top: 40px;

    display: flex;
    flex-flow: row;
    justify-content: flex-end;
`;

class SmartSlidesDialog extends React.Component {
    render() {
        const { closeDialog } = this.props;

        return (
            <BeautifulDialog closeDialog={closeDialog}>
                <ContentContainer>
                    <SmartSlidesImage src={getStaticUrl("/images/ppt-addin/smart-slides-adaptation.gif")} alt="smart slides" />
                    <Header>New to Beautiful.ai? Meet Smart Slides.</Header>
                    <Subheader>Smart Slides use AI to intelligently adjust your layout as you add content. Add an item and watch your slide adapt.</Subheader>
                    <ButtonContainer>
                        <Button onClick={closeDialog} color="primary">got it!</Button>
                    </ButtonContainer>
                </ContentContainer>
            </BeautifulDialog>
        );
    }
}

export default SmartSlidesDialog;
