import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import PresentationEditorController from "legacy-js/editor/PresentationEditor/PresentationEditorController";

const MAX_USERS_TO_RENDER = 10;

const Container = styled.div`
  margin-top: 10px;
  .user {
    opacity: 0.5;
    margin: 5px;
  }
  .user.active {
    opacity: 1;
  } 
  &:empty{
    display:none;
  }
`;

const CollaborationView = () => {
    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        const collaboratorsSubscription = ds.getObservables().collaborators$
            .subscribe(collaborators => {
                setCollaborators(Object.values(collaborators)
                    .filter(({ uid }) => uid !== app.user.id).slice(0, MAX_USERS_TO_RENDER));
            });

        return () => {
            collaboratorsSubscription.unsubscribe();
        };
    }, []);

    const createUser = ({ uid, photoURL, displayName, slideId }) => {
        const status = ds.selection.slide?.id === slideId ? "active" : "";

        return (<div key={uid} className={`user ${status}`} data-uid={uid} onClick={() => {
            PresentationEditorController.setCurrentSlide(slideId);
        }}>
            <div className="avatar" title={displayName}>
                {photoURL ? <img src={photoURL} /> : <i className="micon">person</i>}
            </div>
        </div>);
    };

    return (<Container>
        {collaborators.map(collaborator => createUser(collaborator))}
    </Container>);
};

export default CollaborationView;
