import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { getStaticUrl } from "legacy-js/config";
import { Gap10 } from "legacy-js/react/components/Gap";
import { sanitizeSvg } from "js/core/utilities/dompurify";

const Container = styled.div`
    width: 100%;
    margin-top: 10px;

    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
`;

const Header = styled.div`
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    color: #222;
`;

const ControlsContainer = styled.div.attrs(({ disabled }) => ({
    style: {
        pointerEvents: disabled ? "none" : "auto",
        opacity: disabled ? 0.6 : 1,
    }
}))`
    width: 110px;
    height: 30px;
    background: #ffffff;

    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
`;

const IconButton = styled.div`
    cursor: pointer;
    fill: #666666;

    &:hover {
        fill: #11a9e2;
    }
`;

const SpeedIndicator = styled.div`
    width: 45px;
    margin: 0 2px;

    text-align: center;
    font-weight: 600;
    font-size: 12px;
    color: #222;
`;

export function AnimationSpeedControl({ isAnimating, animationsSpeedMultiplier, setAnimationsSpeedMultiplier }) {
    const [imageData, setImageData] = useState({ turtle: null, bunny: null });

    useEffect(() => {
        Promise.all([
            fetch(getStaticUrl("/images/ui/animation/turtle.svg")).then(res => res.text()),
            fetch(getStaticUrl("/images/ui/animation/bunny.svg")).then(res => res.text())
        ])
            .then(([turtle, bunny]) => setImageData({ turtle, bunny }));
    }, [0]);

    const handleChangeSpeedMultiplier = increase => {
        let newMultiplier;
        if (animationsSpeedMultiplier <= 1) {
            newMultiplier = increase ? animationsSpeedMultiplier * 2 : animationsSpeedMultiplier / 2;
        } else {
            newMultiplier = increase ? animationsSpeedMultiplier + 1 : animationsSpeedMultiplier - 1;
        }
        setAnimationsSpeedMultiplier(Math.clamp(newMultiplier, 0.125, 10));
    };

    return (
        <Container>
            <Header>speed</Header>
            <Gap10 />
            <ControlsContainer disabled={isAnimating}>
                <IconButton onClick={() => handleChangeSpeedMultiplier(false)} dangerouslySetInnerHTML={{ __html: sanitizeSvg(imageData.turtle) }} />
                <SpeedIndicator>
                    {animationsSpeedMultiplier} X
                </SpeedIndicator>
                <IconButton onClick={() => handleChangeSpeedMultiplier(true)} dangerouslySetInnerHTML={{ __html: sanitizeSvg(imageData.bunny) }} />
            </ControlsContainer>
        </Container>
    );
}
