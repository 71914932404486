import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Button, Icon } from "@material-ui/core";

import { ThemeType } from "legacy-common/constants";
import { FeatureType } from "legacy-common/features";
import { builtInFonts, obsoleteBuiltInFontIds } from "legacy-common/fontConstants";
import { defaultSimpleTheme } from "legacy-common/themes";
import AppController from "legacy-js/core/AppController";
import Api from "js/core/api";
import { ds } from "js/core/models/dataService";
import importPPTTheme from "js/core/utilities/importPPTTheme";
import { delay } from "js/core/utilities/promiseHelper";
import uniqueName from "js/core/utilities/uniqueName";
import { pluralize, trackActivity } from "js/core/utilities/utilities";
import db from "js/db";
import PresentationLibraryController from "legacy-js/controllers/PresentationLibraryController";
import { ChooseThemeDialog } from "legacy-js/editor/ThemeEditor/ChooseThemeDialog";
import { GenerateThemeThumbnail } from "legacy-js/editor/ThemeEditor/GenerateThemeThumbnail";
import ImportFontsDialog from "legacy-js/editor/ThemeEditor/ImportFontsDialog";
import ThemeEditorController from "legacy-js/editor/ThemeEditor/ThemeEditorController";
import ThemeColors from "legacy-js/editor/ThemeEditor/panes/ThemeColors";
import ThemeDecoration from "legacy-js/editor/ThemeEditor/panes/ThemeDecoration";
import ThemeFooter from "legacy-js/editor/ThemeEditor/panes/ThemeFooter";
import ThemeSimple from "legacy-js/editor/ThemeEditor/panes/ThemeSimple";
import ThemeTypography from "legacy-js/editor/ThemeEditor/panes/ThemeTypography";
import { ThemePreview } from "legacy-js/editor/ThemeEditor/themePreview/ThemePreview";
import { AnimatedFullScreenContainer } from "legacy-js/editor/components/AnimatedFullScreenContainer";
import { app } from "js/namespaces";
import {
    ShowConfirmationDialog,
    ShowDialog,
    ShowDialogAsync,
    ShowInputDialog,
    ShowWarningDialog
} from "legacy-js/react/components/Dialogs/BaseDialog";
import InputConfirmationDialog from "legacy-js/react/components/Dialogs/InputConfirmationDialog";
import { FlexSpacer, Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import Spinner from "legacy-js/react/components/Spinner";
import { BlueButton, Divider, UINavigation, UINavigationItem, WhiteTextButton } from "legacy-js/react/components/UiComponents";
import { browserHistory } from "js/react/history";
import { themeColors } from "legacy-js/react/sharedStyles";
import { getPresentation } from "js/core/models/presentation";

import PresentationEditorController from "../PresentationEditor/PresentationEditorController";

const ThemeNav = styled(UINavigation)`
    width: 150px;
    display: flex;
    flex-direction: column;

    .ui-navigation-item.selected {
        background: white
    }

    #saved-themes.ui-navigation-item.selected {
        background: rgb(248, 250, 252);
    }
`;

const Template = styled.div`
  background: #ff4500;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  font-weight: 600;
  text-transform: uppercase;
`;

const ThemeHeader = styled.div`
    width: 100%;
    height: 50px;
    background: #333;
    color: white;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    .MuiButton-label {
        font-size: 13px;
        color: #eee;
    }
`;

const UsedId = styled.div`
    font-size: 13px;
    color: white;
`;

const ThemeName = styled.div`
    font-size: 15px;
    color: white;
    font-weight: bold;
    cursor: ${props => props.canEdit ? "pointer" : "default"};

    &:hover {
        color: ${props => props.canEdit ? themeColors.ui_blue : "white"};
    }
`;

const ThemeFrame = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    background: #f1f1f1;
    overflow: hidden;
`;

const ThemeEditorContainer = styled.div`
    background-color: #fff;
    color: #555;
    height: 100%;
    text-transform: uppercase;
    width: 420px;
    overflow-y: scroll;
`;

const ThemeRightFrame = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;
const ThemePreviewFrame = styled.div`
    flex-grow: 1;
`;

const ThemeActions = styled.div`
    height: 70px;
    width: 100%;
    display: flex;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

class ThemeEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pane: null,
            theme: null,
            themeName: null,
            themeType: null,
            iconBaseUrl: props.icons?.baseUrl,
            fonts: [],
            updateTheme: false
        };
        this.themeFontIds = [];
    }

    async componentDidMount() {
        const { themeOrPresentationId } = this.props;

        if (themeOrPresentationId !== ThemeEditorController.themeOrPresentationId) {
            ThemeEditorController.setThemeOrPresentationId(null);
            ThemeEditorController.setActiveThemeEditorPanel("colors");
        }

        let { iconBaseUrl } = this.state;
        if (!iconBaseUrl) {
            let config = await db("config")
                .once("value")
                .then(snapshot => snapshot.val());
            iconBaseUrl = config.iconBaseUrl;
            this.setState({ iconBaseUrl });
        }

        // load presentation
        let presentation, theme, themeName, themeType, sourceTheme, teamThemeUsageCount;
        let saveButtonLabel = "Update Presentation Theme";
        let isNewTheme = false;

        let loadTeamTheme = async themeId => {
            sourceTheme = ds.sharedThemes.get(themeId);
            theme = sourceTheme.attributes;

            themeName = theme.name;
            themeType = ThemeType.TEAM;

            teamThemeUsageCount = (await Api.teamThemes.get({
                teamId: ds.teams.defaultTeamForOrg(AppController.orgId).id,
                sharedThemeId: theme.id
            })).count;
            saveButtonLabel = "Save Team Theme";
        };

        if (themeOrPresentationId == "new-presentation") {
            // user is creating a theme during new presentation flow
            theme = defaultSimpleTheme;
            themeName = "New Presentation Theme";
            themeType = ThemeType.PRESENTATION;
            isNewTheme = true;
            saveButtonLabel = "Create Presentation";
        } else if (themeOrPresentationId == "new-team-theme") {
            // user is creating a new team theme
            theme = defaultSimpleTheme;
            themeName = uniqueName("New Team Theme", ds.sharedThemes.map(theme => theme.get("name")));
            themeType = ThemeType.TEAM;
            isNewTheme = true;
            saveButtonLabel = "Create Team Theme";
        } else if (themeOrPresentationId == "new-saved-theme") {
            // user is creating a new user theme
            theme = defaultSimpleTheme;
            themeName = uniqueName("My Theme", ds.userThemes.map(theme => theme.get("name")));
            themeType = ThemeType.USER;
            isNewTheme = true;
            saveButtonLabel = "Save Theme";
        } else if (ds.sharedThemes.get(themeOrPresentationId)) {
            // user is editing a team theme
            await loadTeamTheme(themeOrPresentationId);
        } else if (ds.userThemes.get(themeOrPresentationId)) {
            // user is editing a saved theme
            sourceTheme = ds.userThemes.get(themeOrPresentationId);
            theme = sourceTheme.attributes;
            themeName = theme.name;
            themeType = ThemeType.USER;
            saveButtonLabel = "Save Theme";
        } else {
            // user is editing a theme from a presentation
            presentation = await getPresentation(themeOrPresentationId, { permission: "write", autoSync: false });
            if (presentation.has("sharedThemeId")) {
                await loadTeamTheme(presentation.get("sharedThemeId"));
            } else {
                theme = await app.themeManager.loadTheme(presentation);
                theme = theme.attributes;
                themeName = "Theme for " + presentation.get("name");
                themeType = ThemeType.PRESENTATION;
            }
        }

        this.setState({
            presentation,
            isNewTheme,
            theme,
            sourceTheme,
            themeName,
            themeType,
            saveButtonLabel,
            teamThemeUsageCount,
            pane: ThemeEditorController.activeThemeEditorPanel ?? "colors",
        });
    }

    componentWillUnmount() {
        const { presentation } = this.state;
        if (presentation) {
            presentation.disconnect();
        }
    }

    handleSelectPane = pane => {
        this.setState({ pane });
    }

    async updateTheme(deltaTheme) {
        const { theme, pane } = this.state;

        // if we're updating to gradient, and not already set to gradient, save the default background color
        if (
            ["gradient", "reverseGradient"].includes(deltaTheme.styleBackground) &&
            !["gradient", "reverseGradient"].includes(theme.styleBackground)
        ) {
            deltaTheme.lastDefaultBackgroundColor = theme.defaultBackgroundColor;
        } else if (deltaTheme.defaultBackgroundColor) {
            deltaTheme.lastDefaultBackgroundColor = deltaTheme.defaultBackgroundColor;
        }

        // Remember the current editor for future editing sessions
        deltaTheme.lastThemeEditor = pane;

        this.setState({
            theme: Object.assign({}, theme, deltaTheme),
            updateTheme: true
        });
    }

    importTheme = () => {
        let onlyTeamThemes = !app.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_CUSTOM_THEMES, AppController.workspaceId);

        let { fonts } = this.state;

        let hadLoadedFonts = !!fonts?.length;

        ShowDialog(ChooseThemeDialog, {
            title: "Import Theme",
            hideUserThemes: onlyTeamThemes,
            hideBuiltInThemes: onlyTeamThemes,
            showEditCurrentTheme: false,
            callback: async theme => {
                if (await ShowConfirmationDialog({ title: "Are you sure you want to import this theme?", message: "The current theme properties will be overwritten by the imported theme." })) {
                    this.themeFontIds = theme.getFontIds();
                    if (hadLoadedFonts) {
                        this.setState({
                            fonts: []
                        });
                        // Wait a frame
                        await delay(10);
                    }

                    // Only import fonts and colors
                    let {
                        colors,
                        defaultBackgroundColor,
                        defaultSlideColor,
                        fontBodyFontId,
                        fontBodyLetterSpacing,
                        fontBodyLineHeight,
                        fontBodyScaling,
                        fontBodyTextTransform,
                        fontBodyWeight,
                        fontHeaderFontId,
                        fontHeaderLetterSpacing,
                        fontHeaderLineHeight,
                        fontHeaderScaling,
                        fontHeaderTextTransform,
                        fontHeaderWeight,
                        fontScale,
                        fontTitleFontId,
                        fontTitleLetterSpacing,
                        fontTitleLineHeight,
                        fontTitleScaling,
                        fontTitleTextTransform,
                        fontTitleWeight,
                        originalFontBodyFontId,
                        originalFontHeaderFontId,
                        originalFontTitleFontId,
                        palette_name,
                        backgroundGradients = [],
                        backgroundImages = [],
                        logo = null,
                        logo_dark = null,
                    } = theme.attributes;

                    let deltaTheme = {
                        colors,
                        defaultBackgroundColor,
                        defaultSlideColor,
                        fontBodyFontId,
                        fontBodyLetterSpacing,
                        fontBodyLineHeight,
                        fontBodyScaling,
                        fontBodyTextTransform,
                        fontBodyWeight,
                        fontHeaderFontId,
                        fontHeaderLetterSpacing,
                        fontHeaderLineHeight,
                        fontHeaderScaling,
                        fontHeaderTextTransform,
                        fontHeaderWeight,
                        fontScale,
                        fontTitleFontId,
                        fontTitleLetterSpacing,
                        fontTitleLineHeight,
                        fontTitleScaling,
                        fontTitleTextTransform,
                        fontTitleWeight,
                        palette_name,
                        backgroundGradients,
                        backgroundImages,
                        logo,
                        logo_dark
                    };

                    if (originalFontBodyFontId) {
                        deltaTheme.originalFontBodyFontId = originalFontBodyFontId;
                    }
                    if (originalFontHeaderFontId) {
                        deltaTheme.originalFontHeaderFontId = originalFontHeaderFontId;
                    }
                    if (originalFontTitleFontId) {
                        deltaTheme.originalFontTitleFontId = originalFontTitleFontId;
                    }

                    this.updateTheme(deltaTheme);
                }
            }
        });
    };

    importPPTTheme = async () => {
        let { fonts } = this.state;

        if (
            await ShowConfirmationDialog({
                title: "Import colors and fonts from a PowerPoint",
                message: "Start with your brand colors and fonts in place, and customize your theme from there.",
                okButtonLabel: "Select File"
            })
        ) {
            let importedThemeProperties = await importPPTTheme("theme");

            let headerFont = fonts.find(font => importedThemeProperties.headerFont.startsWith(font.label));
            let bodyFont = fonts.find(font => importedThemeProperties.bodyFont.startsWith(font.label));

            let headerFontId = headerFont?.id;
            let headerFontWeight = importedThemeProperties.headerFontWeight;
            let bodyFontId = bodyFont?.id;
            let bodyFontWeight = importedThemeProperties.bodyFontWeight;

            if (!headerFont || !bodyFont) {
                await ThemeEditorController.loadFonts();
                let results = await ShowDialogAsync(ImportFontsDialog, {
                    headerFont: importedThemeProperties.headerFont,
                    headerFontWeight: importedThemeProperties.headerFontWeight,
                    bodyFont: importedThemeProperties.bodyFont,
                    bodyFontWeight: importedThemeProperties.bodyFontWeight,
                });
                if (results) {
                    headerFontId = results.headerFont.fontId;
                    headerFontWeight = results.headerFont.weight;
                    bodyFontId = results.bodyFont.fontId;
                    bodyFontWeight = results.bodyFont.weight;
                } else {
                    return;
                }
            }

            this.updateTheme({
                colors: importedThemeProperties.colors,
                fontHeaderFontId: headerFontId,
                fontHeaderWeight: headerFontWeight,
                fontHeaderScaling: 100,
                fontHeaderLetterSpacing: 0,
                fontHeaderLineSpacing: 1.6,
                fontBodyFontId: bodyFontId,
                fontBodyWeight: bodyFontWeight,
                fontBodyScaling: 100,
                fontBodyLetterSpacing: 0,
                fontBodyLineSpacing: 1.6,
                fontTitleFontId: headerFontId,
                fontTitleWeight: headerFontWeight,
                fontTitleScaling: 100,
                fontTitleLetterSpacing: 0,
                fontTitleLineSpacing: 1.6
            });
        }
    };

    handleEditThemeName = async () => {
        const { theme, themeType, themeName } = this.state;

        if (themeType == ThemeType.TEAM || themeType == ThemeType.USER) {
            const newName = await ShowInputDialog({
                title: "Enter a name for your theme",
                value: themeName,
                trimInput: true
            });
            if (newName) {
                theme.name = newName;
                this.setState({ themeName: newName, themeNameWasChanged: true });
            }
        }
    }

    handleSaveChanges = async () => {
        const { closeDialog, returnPath, themeOrPresentationId } = this.props;
        const { theme, isNewTheme, themeName, presentation, themeType, sourceTheme, themeNameWasChanged, teamThemeUsageCount, pane } = this.state;

        // Store pane for future editing sessions
        ThemeEditorController.setActiveThemeEditorPanel(pane);
        ThemeEditorController.setThemeOrPresentationId(themeOrPresentationId);

        switch (themeType) {
            case ThemeType.USER:
                if (isNewTheme) {
                    await ds.userThemes.saveUserTheme(theme, themeName, AppController.orgId);
                } else {
                    sourceTheme.update({
                        ...theme,
                        thumbnailId: await GenerateThemeThumbnail(theme)
                    });
                }
                browserHistory.goBack();
                break;
            case ThemeType.TEAM:
                if (isNewTheme) {
                    let newName = await ShowInputDialog({ title: "Enter a name for your new team theme", value: themeName });
                    // Input dialog returns false if user cancels
                    if (newName === false) {
                        return;
                    }

                    theme.name = newName ?? theme.name;

                    let newTheme = await app.themeManager.createTeamTheme(theme, AppController.orgId, ds.teams.defaultTeamForOrg(AppController.orgId), theme.name);
                    newTheme.update({ thumbnailId: await GenerateThemeThumbnail(theme) });
                } else {
                    if (teamThemeUsageCount > 0) {
                        let save = await ShowConfirmationDialog({
                            title: "Are you sure you want to save changes to this Team Theme?",
                            message: `There ${pluralize("is ## presentation", teamThemeUsageCount, "are ## presentations")} will be affected by this change.`
                        });
                        if (!save) return;
                    }

                    sourceTheme.update(theme, { replaceKeys: true });
                    sourceTheme.update({ thumbnailId: await GenerateThemeThumbnail(sourceTheme.attributes) });
                }

                browserHistory.goBack();
                break;
            case ThemeType.PRESENTATION:
                let presentationId = presentation?.id;
                if (isNewTheme) {
                    const createTheme = await ds.userThemes.saveUserTheme(theme, themeName, AppController.orgId);
                    const newPresentation = await PresentationLibraryController.createPresentation({
                        workspaceId: AppController.workspaceId,
                        theme: createTheme
                    });
                    await app.themeManager.saveThemeToPresentation(createTheme, newPresentation);
                    presentationId = newPresentation.id;
                } else {
                    await app.themeManager.saveThemeToPresentation({ attributes: theme }, presentation);
                }

                if (window.isPPTAddin && closeDialog) {
                    PresentationEditorController.updateTheme();
                    closeDialog();
                } else {
                    // Go to the presentation with the currect slide index
                    if (returnPath) AppController.goToRoute(returnPath);
                    // keeping it just if a user removes the returnPath from the URL and then refreshes the page - it will still work
                    // but will go to the first slide
                    else AppController.showEditor({ presentationId });
                }

                break;
        }
    }

    handleBackButton = () => {
        this.handleCancel();
    }

    handleCancel = () => {
        const { closeDialog } = this.props;

        if (window.isPPTAddin && closeDialog) {
            closeDialog();
        } else {
            browserHistory.goBack();
        }
    };

    handleSaveToLibrary = () => {
        const { theme } = this.state;

        let hasCustomFont = false;
        const styles = ["Body", "Title", "Header"];
        const workspaceId = AppController.workspaceId;

        for (const style of styles) {
            const fontId = theme[`font${style}FontId`];
            if (fontId && !builtInFonts[fontId]) {
                hasCustomFont = true;
            } else {
                const obsoleteFontName = theme[`font${style}`];
                if (obsoleteFontName && !obsoleteBuiltInFontIds.includes(obsoleteFontName)) {
                    hasCustomFont = true;
                }
            }
        }
        if (hasCustomFont) {
            const customFontsEnabled = app.user.features.isFeatureEnabled(FeatureType.CUSTOM_FONTS, workspaceId);
            if (!customFontsEnabled) {
                ShowWarningDialog({
                    title: "Cannot Save Theme",
                    message: "This theme has custom fonts - in order to save this theme, you will need to upgrade your account."
                });
                return;
            }
        }

        const orgId = AppController.orgId;

        ShowDialog(InputConfirmationDialog, {
            title: "Save theme to library",
            message: "You can save this theme to your own saved theme library for reuse in other presentations.",
            okButtonLabel: "Save",
            input: {
                type: "text",
                placeholder: "Enter theme name...",
                value: uniqueName("My Theme", ds.userThemes.map(theme => theme.get("name")))
            },
            acceptCallback: async value => {
                let themeName = value;
                // reserved name for ui
                if (themeName === "Theme") {
                    ShowWarningDialog({
                        title: "Cannot Save Theme",
                        message: `You can't use "Theme" as your theme name.`
                    });
                    return;
                }
                // handles userThemes in org context OR userThemes in personal context
                let existingTheme = orgId ? ds.userThemes.find({ name: themeName, orgId }) : ds.userThemes.find({ name: themeName });
                if (existingTheme && !ds.userThemes.find({ name: themeName }).has("orgId")) {
                    ShowConfirmationDialog({
                        message: (
                            <Fragment>
                                A theme named <strong>{themeName}</strong> already exists. Do you want to replace it?
                            </Fragment>
                        ),
                        acceptCallback: async () => {
                            const newTheme = ds.userThemes.saveUserTheme(theme, themeName, orgId);
                            trackActivity("Theme", "OverwriteUserTheme", null, null, { theme_id: newTheme.id, theme_name: themeName }, { audit: false });
                        }
                    });
                } else {
                    const newTheme = ds.userThemes.saveUserTheme(theme, themeName, orgId);
                    if (ds.selection.presentation) {
                        ds.selection.presentation.update({ themeId: newTheme.id });
                    }
                    trackActivity("Theme", "NewUserTheme", null, null, { theme_id: newTheme.id, theme_name: themeName }, { audit: false });
                }
            }
        });
    };

    renderPane = () => {
        const { pane, theme, iconBaseUrl } = this.state;

        let childProps = {
            ...this.props,
            theme,
            iconBaseUrl,
            update: deltaThemeAttributes => this.updateTheme(deltaThemeAttributes),
        };

        switch (pane) {
            case "quick":
                return <ThemeSimple {...childProps} />;
            case "colors":
                return <ThemeColors {...childProps} />;
            case "typography":
                return <ThemeTypography {...childProps} />;
            case "decoration":
                return <ThemeDecoration {...childProps} />;
            case "footer":
                return <ThemeFooter {...childProps} />;
        }
    }

    renderThemeName = () => {
        const { themeName, themeType, teamThemeUsageCount, isNewTheme } = this.state;

        if (themeType == ThemeType.TEAM) {
            return (<>
                {themeType == ThemeType.TEAM && <Template>Team Theme</Template>}
                <Gap10 />
                {!isNewTheme && <ThemeName id="theme-name-editor" canEdit={themeType == ThemeType.TEAM || themeType == ThemeType.USER}
                    onClick={this.handleEditThemeName}>{themeName}</ThemeName>}
                <Gap10 />
                {(themeType == ThemeType.TEAM && teamThemeUsageCount > 0) && <UsedId>
                    Used in {pluralize("## presentation", teamThemeUsageCount, "## presentations")}
                </UsedId>}
            </>);
        }

        return (<>
            <Gap20 />
            <ThemeName canEdit={themeType == ThemeType.TEAM || themeType == ThemeType.USER}
                onClick={this.handleEditThemeName}>{themeName}</ThemeName>
        </>);
    };

    render() {
        const { presentation, pane, theme, themeName, themeThumbnail, themeType, iconBaseUrl, fonts, isNewPresentation, saveButtonLabel, teamThemeUsageCount } = this.state;

        let childProps = {
            ...this.props,
            theme,
            iconBaseUrl,
            update: deltaThemeAttributues => this.updateTheme(deltaThemeAttributues),
        };

        if (!theme) {
            return <Spinner>Loading...</Spinner>;
        }

        return (
            <AnimatedFullScreenContainer onEscapeKey={this.handleCancel} id="theme-editor">
                <ThemeNav
                    selected={pane}
                    onSelect={this.handleSelectPane}
                    setAsPlaceholder={true}
                    fullHeight
                >
                    <UINavigationItem
                        hidden
                        id="quick"
                        title="Quick Theme"
                        icon="auto_fix_high"
                        vertical
                    />
                    <Divider hidden color="#444" margin={15} />
                    <UINavigationItem
                        id="colors"
                        title="Colors"
                        icon="color_lens"
                        vertical
                    />
                    <UINavigationItem
                        id="typography"
                        title="Typography"
                        icon="format_size"
                        vertical
                    />
                    <UINavigationItem
                        id="decoration"
                        title="Decoration"
                        icon="gradient"
                        vertical
                    />
                    <UINavigationItem
                        id="footer"
                        title="Footer"
                        icon="video_label"
                        vertical
                    />
                </ThemeNav>

                <FlexBox fill vertical top left>
                    <>
                        <ThemeHeader>
                            {this.renderThemeName()}
                            <FlexSpacer />
                            <WhiteTextButton
                                onClick={this.importTheme}>
                                <Icon>playlist_add</Icon>
                                Import Theme...
                            </WhiteTextButton>
                            <Gap20 />
                            <WhiteTextButton
                                onClick={this.importPPTTheme}>
                                <Icon>upload</Icon>
                                Import from PPT...
                            </WhiteTextButton>
                            <Gap20 />
                            <WhiteTextButton
                                onClick={this.handleSaveToLibrary}>
                                <Icon>star</Icon>
                                Add to Saved Themes...
                            </WhiteTextButton>

                        </ThemeHeader>
                        <ThemeFrame>
                            <ThemeEditorContainer>
                                {this.renderPane()}
                            </ThemeEditorContainer>

                            <ThemeRightFrame>
                                <ThemePreviewFrame>
                                    <ThemePreview theme={theme} />
                                </ThemePreviewFrame>
                                <ThemeActions>
                                    <FlexSpacer />
                                    <Button onClick={this.handleCancel}>Cancel</Button>
                                    <BlueButton
                                        data-cy="theme-editor-create-btn"
                                        onClick={this.handleSaveChanges}>{saveButtonLabel}</BlueButton>
                                </ThemeActions>
                            </ThemeRightFrame>
                        </ThemeFrame>
                    </>
                </FlexBox>
            </AnimatedFullScreenContainer>
        );
    }
}

export default ThemeEditorController.withState(ThemeEditor);
