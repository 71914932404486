import React from "react";
import styled from "styled-components";

import { ShortcutContent } from "legacy-js/editor/shortcutView";
import { BeautifulDialog } from "legacy-js/react/components/Dialogs/BaseDialog";

const ContentContainer = styled.div`
    background: #f1f1f1;
    padding: 10px;
    width: 280px;
`;
class ShortcutsDialog extends React.Component {
    render() {
        const { closeDialog } = this.props;

        return (
            <BeautifulDialog closeDialog={closeDialog} fullWidth={false}>
                <ContentContainer>
                    <ShortcutContent />
                </ContentContainer>
            </BeautifulDialog>
        );
    }
}

export default ShortcutsDialog;
