import React, { Component, Fragment } from "react";
import { _ } from "legacy-js/vendor";
import { Button, Icon, IconButton, Select, MenuItem } from "@material-ui/core";
import { v4 as uuid } from "uuid";
import UploadLogo from "legacy-js/editor/ThemeEditor/components/Footer/UploadLogo";
import { AbsoluteBox, FlexBox } from "legacy-js/react/components/LayoutGrid";
import styled from "styled-components";
import { FlexSpacer, Gap10, Gap20 } from "legacy-js/react/components/Gap";
import { SimpleLabel } from "legacy-js/react/components/SimpleLabel";
import { ShowDialogAsync } from "../../../react/components/Dialogs/BaseDialog";
import { GradientBuilder } from "../GradientBuilder";
import { Gradient } from "./Gradient";
import { themeColors } from "../../../react/sharedStyles";

export class ThemeGradients extends Component {
    constructor(props) {
        super(props);

        this.state = {
            backgroundGradients: _.cloneDeep(props.theme.backgroundGradients) || []
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme.backgroundGradients != this.props.theme.backgroundGradients) {
            this.setState({ backgroundGradients: _.cloneDeep(this.props.theme.backgroundGradients) });
        }
    }

    handleAddGradient = async () => {
        let gradient = await ShowDialogAsync(GradientBuilder);
        if (!gradient) return;

        let backgroundGradients = this.state.backgroundGradients || [];

        backgroundGradients.push({
            id: uuid(),
            colorStyle: "color",
            ...gradient
        });

        this.setState({ backgroundGradients });
        this.props.update({ backgroundGradients: _.cloneDeep(backgroundGradients) });
    }

    handleUpdateGradient = (props, backgroundId) => {
        let background = this.state.backgroundGradients.find(bg => bg.id == backgroundId);
        if (background) {
            background = Object.assign(background, props);

            this.props.update({ backgroundGradients: _.cloneDeep(this.state.backgroundGradients) });
            this.setState({ backgroundGradients: this.state.backgroundGradients });
        }
    }

    handleDeleteGradient = backgroundId => {
        let background = this.state.backgroundGradients.find(bg => bg.id == backgroundId);
        if (background) {
            this.state.backgroundGradients.remove(background);

            let props = { backgroundGradients: _.cloneDeep(this.state.backgroundGradients) };
            if (backgroundId == this.props.theme.defaultBackgroundColor) {
                props.defaultBackgroundColor = "background_light";
            }
            this.props.update(props);

            this.setState({ backgroundGradients: this.state.backgroundGradients });
        }
    }

    render() {
        let { backgroundGradients } = this.state;

        return (
            <FlexBox vertical left gap={10}>
                {backgroundGradients.map(bg => (
                    <BackgroundGradient
                        key={bg.id}
                        backgroundGradient={bg}
                        onUpdate={props => this.handleUpdateGradient(props, bg.id)}
                        onDelete={() => this.handleDeleteGradient(bg.id)}
                    />
                ))}
                <Button size="small" onClick={this.handleAddGradient}><Icon color="primary" style={{ marginRight: -5 }}>add_circle_outline</Icon>Add</Button>
            </FlexBox>

        );
    }
}

const BGGradientContainer = styled.div`
    display: flex;
    align-items: center;
    background: white;

    .MuiIconButton-root {
        opacity: 0;
    }

    &:hover {
        background: #f5f5f5;
        outline: solid 5px #f5f5f5;

        .MuiIconButton-root {
            opacity: 1;
        }
    }

`;

export const GradientPreview = styled.div`
    width: 128px;
    height: 80px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .MuiIconButton-root {
        opacity: 0;
        background: ${themeColors.ui_blue};
        color: white;
    }
`;

class BackgroundGradient extends Component {
    handleEditGradient = async () => {
        let { backgroundGradient, onUpdate } = this.props;

        let gradient = await ShowDialogAsync(GradientBuilder, { gradient: backgroundGradient });
        if (!gradient) return;

        onUpdate(gradient);
    }

    render() {
        let { backgroundGradient, onUpdate, onDelete } = this.props;

        return (
            <BGGradientContainer>
                <GradientPreview>
                    <Gradient gradient={backgroundGradient} />
                    <AbsoluteBox>
                        <IconButton onClick={this.handleEditGradient}><Icon>edit</Icon></IconButton>
                    </AbsoluteBox>
                </GradientPreview>
                <Gap10 />
                <FlexBox left middle vertical>
                    <SimpleLabel>Treat as</SimpleLabel>
                    <Select size="small" variant="outlined"
                        value={backgroundGradient.colorStyle || "light"}
                        onChange={event => onUpdate({ colorStyle: event.target.value })}
                    >
                        <MenuItem value={"light"}>Light</MenuItem>
                        <MenuItem value={"dark"}>Dark</MenuItem>
                        <MenuItem value={"color"}>Color</MenuItem>
                    </Select>
                </FlexBox>
                <Gap20 />
                <FlexSpacer />
                <IconButton onClick={onDelete}><Icon>close</Icon></IconButton>
                <Gap10 />
            </BGGradientContainer>
        );
    }
}
